<template>
  <div>
    <b-modal
      id="modal-addOn"
      ref="modalAddOn"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-disabled
      cancel-title="Close"
      centered
      title="Suppletie"
      :hide-footer="true"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refAddOnFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(handleAddOn)"
        >
          <validation-provider
            name="newContractDuration"
            rules="integer"
            vid="required_if_newContractDuration"
          >
            <b-form-group
              label="Nieuwe looptijd"
              label-for="newContractDuration"
            >
              <b-form-input
                id="newContractDuration"
                v-model.number="addOnData.newContractDuration"
                :state="validateSuppletion"
                type="number"
              />
            </b-form-group>
          </validation-provider>
          <validation-provider
            name="newInsuredAmount"
            rules="integer"
            vid="required_if_new_insured_amount"
          >
            <b-form-group>
              <label>Nieuw verzekerd kapitaal / jaarloon</label>
              <b-form-input
                id="newInsuredAmount"
                v-model.number="addOnData.newInsuredAmount"
                :state="validateSuppletion"
                type="number"
                step=".01"
              />
              <b-form-invalid-feedback id="input-live-feedback">
                <br>Vul minimaal 1 van de 2 velden in aub
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner
              v-if="showAddOnSpinner"
              small
              class="mr-1"
            />
            Opslaan
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-pdf-viewer"
      ref="modalPdfViewer"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-title="Close"
      centered
      title=""
      :hide-footer="true"
      size="lg"
    >
      <pdf-viewer />
    </b-modal>
    <b-modal
      id="modal-validate-documents"
      ref="modalValidateDocumentsRelation"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-title="Close"
      centered
      title="Documenten relatie valideren"
      :hide-footer="true"
      size="xl"
    >
      <app-relation-documents
        :relation-id="selectedRelationDocumentValidation.id"
        :files="selectedRelationDocumentValidation.files"
        :relation-type="selectedRelationDocumentValidation.type"
        @refetch-data="refetchOrderDetailData"
      />
    </b-modal>
    <b-modal
      id="modal-medical-check"
      ref="modalMedicalAllowance"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-disabled
      cancel-title="Close"
      centered
      title="Medische Acceptatie"
      :hide-footer="true"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(medicalAllowance)"
        >
          <validation-provider
            #default="validationContext"
            name="medicalAllowancePercentage"
            rules="required|decimal|between:0,400"
          >
            <b-form-group>
              <label>Medische acceptatie toelage (%)</label>
              <b-form-input
                id="medicalAllowancePercentage"
                v-model="medicalAllowanceObject.medicalAllowancePercentage"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                type="number"
                step=".01"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner
              v-if="showMedicalAllowanceSpinner"
              small
              class="mr-1"
            />
            Opslaan
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-cancel-offer"
      ref="modalCancelOffer"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-disabled
      cancel-title="Close"
      centered
      title="Annuleren"
      :hide-footer="true"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(cancelOffer)"
        >
          <validation-provider
            #default="validationContext"
            name="cancelReason"
            rules="required"
          >
            <b-form-group>
              <label>Annulerings reden</label>
              <b-form-textarea
                id="cancelReason"
                v-model="cancelOfferObject.declineReason"
                rows="2"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner
              v-if="showCancelOfferSpinner"
              small
              class="mr-1"
            />
            Opslaan
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-disbarment-offer"
      ref="modalRoyementOffer"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-disabled
      cancel-title="Close"
      centered
      title="Aanvraag royement"
      :hide-footer="true"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(handelRoyementRequest)"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="disbarmentRequestDate"
                rules="required|date"
              >
                <b-form-group
                  label="Datum van stopzetting"
                  label-for="disbarmentRequestDate"
                  :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                    id="disbarmentRequestDate"
                    v-model="royementOfferObject.disbarmentRequestDate"
                    :config="royementDateConfig"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="disbarmentRequestNote"
                rules="required"
              >
                <b-form-group>
                  <label>Reden</label>
                  <b-form-textarea
                    id="disbarmentRequestNote"
                    v-model="royementOfferObject.disbarmentRequestNote"
                    rows="2"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <b-spinner
                  v-if="showRoyementOfferSpinner"
                  small
                  class="mr-1"
                />
                Aanvragen
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-upload"
      ref="modalUpload"
      hide-footer
      centered
      size="md"
      title="Bestanden Uploaden"
    >
      <b-card>
        <b-row>
          <b-col
            cols="12"
          >
            <b-card-text>
              <b-button
                variant="primary"
                :disabled="uploadLoader"
                @click="$refs.refInputEl.click()"
              >
                <b-spinner
                  v-if="uploadLoader"
                  small
                  class="mr-1"
                />
                <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @input="uploadDocument"
                >
                <span class="d-none d-sm-inline">Uploaden</span>
                <feather-icon
                  icon="EditIcon"
                  class="d-inline d-sm-none"
                />
              </b-button>
            </b-card-text>
          </b-col>
          <b-col
            class="mt-1"
            cols="12"
          >
            <b-card-text
              cols="12"
              class="text-muted"
            >
              Toegestaan PDF, GIF of PNG. Maximale grootte van 2MB.
            </b-card-text>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
    <b-row
      v-if="processedBy.inProcess"
    >
      <b-col
        cols="12"
      >
        <b-alert
          v-height-fade
          fade
          lg
          show
          variant="success"
        >
          <div class="alert-body p-1">
            <feather-icon
              class="mr-25"
              icon="EditIcon"
            />
            <span class="ml-25">In behandeling genomen op {{ processedBy.inProcessTimestamp }} door: {{ processedBy.processedByName }}</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        v-if="polisInformation"
        cols="12"
        md="8"
      >
        <b-card
          title="Orderinformatie"
        >
          <b-row>

            <!-- User Info: Left col -->
            <b-col
              cols="12"
              xl="6"
            >
              <table class="mt-2 mt-xl-0 w-100 orderInfo">
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="ActivityIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Order ID</span>

                  </th>
                  <td class="pb-50">
                    {{ orderId }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="ActivityIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Status</span>

                  </th>
                  <td class="pb-50">
                    {{ statusParser(polisInformation.status) }}
                  </td>
                </tr>
                <tr
                  v-if="polisInformation.status === 93 || polisInformation.status === 92"
                >
                  <th class="pb-50">
                    <feather-icon
                      icon="InfoIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Royement reden</span>

                  </th>
                  <td class="pb-50">
                    {{ polisInformation.disbarmentRequestNote }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="PackageIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Product</span>

                  </th>
                  <td class="pb-50">
                    {{ selectedProduct.name }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="ClockIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Aanvraagdatum</span>

                  </th>
                  <td class="pb-50">
                    {{ polisInformation.timestamp }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Gebruiker</span>

                  </th>
                  <td class="pb-50">
                    {{ polisInformation.user }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="BriefcaseIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Agent</span>

                  </th>
                  <td class="pb-50">
                    {{ polisInformation.companyName }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="ClipboardIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Opmerkingen</span>

                  </th>
                  <td class="pb-50">
                    {{ polisInformation.note ? polisInformation.note : "Geen" }}
                  </td>
                </tr>
                <tr
                  v-if="polisInformation.collectiveID"
                >
                  <th class="pb-50">
                    <feather-icon
                      icon="UmbrellaIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Collectief</span>

                  </th>
                  <td class="pb-50">
                    {{ polisInformation.collectiveID }}
                  </td>
                </tr>
              </table>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="6"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr
                  v-if="polisInformation.status === 93 || polisInformation.status === 92"
                >
                  <th class="pb-50">
                    <feather-icon
                      icon="ClockIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Royements datum</span>

                  </th>
                  <td class="pb-50">
                    {{ formatDate(polisInformation.disbarmentRequestDate) }}
                  </td>
                </tr>
                <tr
                  v-if="polisInformation.status === 92"
                >
                  <th class="pb-50">
                    <feather-icon
                      icon="InfoIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Royement acceptatie</span>

                  </th>
                  <td class="pb-50">
                    {{ polisInformation.disbarmentNote }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="RepeatIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Betaalfrequentie</span>
                  </th>
                  <td class="pb-50">
                    {{ polisInformation.paymentFrequency }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="ClockIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Startdatum</span>
                  </th>
                  <td class="pb-50">
                    {{ polisInformation.startDate }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="CreditCardIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Betaalmethode</span>
                  </th>
                  <td>
                    {{ polisInformation.paymentMethod }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="BriefcaseIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Verzekerd Kapitaal</span>
                  </th>
                  <td class="pb-50">
                    {{ parseFloat(polisInformation.insuredAmount).toFixed(2) }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="DollarSignIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Valuta</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ polisInformation.currency }}
                  </td>
                </tr>
                <tr
                  v-show="polisInformation.contractDuration"
                >
                  <th class="pb-50">
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Looptijd</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ polisInformation.contractDuration }} jaar
                  </td>
                </tr>
                <tr
                  v-if="polisInformation.employmentDate !== ''"
                >
                  <th class="pb-50">
                    <feather-icon
                      icon="UsersIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Datum indiensttreding</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ polisInformation.employmentDate }}
                  </td>
                </tr>
                <tr
                  v-if="polisInformation.employerContributionPercentage"
                >
                  <th class="pb-50">
                    <feather-icon
                      icon="PercentIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Werkgeversbijdrage (%)</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ polisInformation.employerContributionPercentage }}
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        v-if="selectedProduct"
        cols="12"
        md="4"
      >
        <b-card
          title="Premieinformatie"
        >
          <b-row>

            <!-- User Info: Left col -->
            <b-col
              cols="12"
              xl="12"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr
                  v-if="polisInformation.premiumFactor && polisInformation.productType === 1"
                >
                  <th class="pb-50">

                    <span class="font-weight-bold">Premiefactor</span>

                  </th>
                  <td class="pb-50">
                    {{ polisInformation.premiumFactor }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span class="font-weight-bold">Medische toeslag</span>
                  </th>
                  <td class="pb-50">
                    {{ polisInformation.premiumAllowancePercentage }} %
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span class="font-weight-bold">Automatische Acceptatie</span>
                  </th>
                  <td class="pb-50">
                    {{ polisInformation.canAutoAccept ? "Ja" : "Nee" }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span class="font-weight-bold">Automatische prolongatie</span>
                  </th>
                  <td class="pb-50">
                    {{ polisInformation.product.canAutoProlongate && polisInformation.product.canAutoProlongate === 1 ? "Ja" : "Nee" }}
                  </td>
                </tr>
                <tr
                  v-if="polisInformation.policyCost"
                >
                  <th class="pb-50">

                    <span class="font-weight-bold">Poliskosten</span>
                  </th>
                  <td class="pb-50">
                    {{ polisInformation.currency }} {{ parseFloat(polisInformation.policyCost).toFixed(2) }}
                  </td>
                </tr>
                <tr
                  v-if="polisInformation.grossPremium"
                >
                  <th class="pb-50">

                    <span class="font-weight-bold">Bruto Premie</span>
                  </th>
                  <td class="pb-50">
                    {{ polisInformation.currency }} {{ parseFloat(polisInformation.grossPremium).toFixed(2) }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span class="font-weight-bold">Korting</span>
                  </th>
                  <td class="pb-50">
                    {{ polisInformation.currency }} {{ parseFloat((polisInformation.discount + polisInformation.extraDiscount)).toFixed(2) }}
                  </td>
                </tr>
                <tr
                  v-if="polisInformation.premium"
                >
                  <th class="pb-50">

                    <span class="font-weight-bold">Premie</span>
                  </th>
                  <td class="pb-50">
                    {{ polisInformation.currency }} {{ parseFloat(polisInformation.premium).toFixed(2) }}
                  </td>
                </tr>
                <tr
                  v-if="polisInformation.productType === 2"
                >
                  <th class="pb-50">

                    <span class="font-weight-bold">Latent IP</span>
                  </th>
                  <td class="pb-50">
                    {{ polisInformation.currency }}  {{ polisInformation.yearLatentIP }}
                  </td>
                </tr>
                <tr
                  v-if="polisInformation.productType === 2"
                >
                  <th class="pb-50">

                    <span class="font-weight-bold">Latent PP</span>
                  </th>
                  <td class="pb-50">
                    {{ polisInformation.currency }}  {{ polisInformation.yearLatentPP }}
                  </td>
                </tr>
                <tr
                  v-if="polisInformation.productType === 2"
                >
                  <th class="pb-50">

                    <span class="font-weight-bold">Latent WZ</span>
                  </th>
                  <td class="pb-50">
                    {{ polisInformation.currency }}  {{ polisInformation.yearLatentWZ }}
                  </td>
                </tr>
                <tr
                  v-if="polisInformation.productType === 2"
                >
                  <th class="pb-50">

                    <span class="font-weight-bold">Bijdrage Werkgever %</span>
                  </th>
                  <td class="pb-50">
                    {{ polisInformation.employerContributionPercentage }} %
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>

        </b-card>
      </b-col>

      <b-col
        v-if="polisHolderData"
        cols="12"
        md="12"
      >
        <b-card
          title="Verzekeringsnemer"
        >
          <b-table
            ref="refPolisHolderListTable"
            class="position-relative"
            :items="polisHolderData"
            responsive
            :fields="polisHolderTableColumns"
            primary-key="id"
            show-empty
            empty-text="Geen overeenkomende records gevonden"
          >
            <!-- Column: Id -->
            <template #cell(id)="data">
              <b-link
                class="font-weight-bold"
              >
                #{{ data.value }}
              </b-link>
            </template>
            <!-- Column: Relations -->
            <template #cell(relation)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :text="avatarText(getFullName(data.item))"
                    :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                  />
                </template>
                <b-link
                  :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ getFullName(data.item) }}
                </b-link>
                <div class="text-muted">
                  {{ data.item.email }}
                </div>
              </b-media>
            </template>

            <template #cell(type)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.type === 2"
                  class="cursor-pointer"
                  icon="HomeIcon"
                  size="20"
                />
                <feather-icon
                  v-if="data.item.type === 1"
                  class="cursor-pointer"
                  icon="UserIcon"
                  size="20"
                />
                {{ resolveTypeVariant(data.value) }}
              </div>
              <div
                v-if="data.item.riskProfile && data.item.riskProfile !== '' && data.item.riskProfile !== 'NONE'"
                class="text-nowrap"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="CompassIcon"
                  size="20"
                  :style="`color:${resolveRiskProfileColor(data.item.riskProfile)};`"
                />
                {{ data.item.riskProfile === 'LOW' || data.item.riskProfile === 'NONE' ? 'Laag risicoprofiel' : '' }}{{ data.item.riskProfile === 'MEDIUM' ? 'Medium risicoprofiel' : '' }}{{ data.item.riskProfile === 'HIGH' ? 'Hoog risicoprofiel' : '' }}
              </div>
            </template>

            <template #cell(documentStatus)="data">
              <b-badge :variant="documentStatusVariantParser(data.value)">
                {{ documentStatusParser(data.value) }}
              </b-badge>
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">

              <div class="text-nowrap">
                <b-link
                  :to="{ name: 'apps-relations-edit', params: { id: data.item.id }}"
                >
                  <feather-icon
                    :id="`product-polis-holder-row-${data.item.id}-eye-icon`"
                    class="cursor-pointer"
                    size="16"
                    icon="EyeIcon"
                  />
                </b-link>
                <feather-icon
                  :id="`document-polis-holder-row-${data.item.id}-validate-icon`"
                  class="cursor-pointer mx-1"
                  size="16"
                  icon="FolderPlusIcon"
                  style="color:#fdb813"
                  @click="openModalValidateDocumentsRelation(data.item,1)"
                />
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col
        v-if="insuredPartiesData"
        cols="12"
        md="12"
      >
        <b-card
          title="Verzekerde"
        >
          <b-table
            ref="refInsuredPartiesListTable"
            class="position-relative"
            :items="insuredPartiesData"
            responsive
            :fields="insuredPartiesTableColumns"
            primary-key="id"
            show-empty
            empty-text="Geen overeenkomende records gevonden"
          >
            <!-- Column: Id -->
            <template #cell(id)="data">
              #{{ data.item.id }}
            </template>

            <!-- Column: Relations -->
            <template #cell(relation)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :text="avatarText(getFullName(data.item))"
                    :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                  />
                </template>
                <b-link
                  :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ getFullName(data.item) }}
                </b-link>
                <div class="text-muted">
                  {{ data.item.email }}
                </div>
              </b-media>
            </template>
            <template #cell(type)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.type === 2"
                  class="cursor-pointer"
                  icon="HomeIcon"
                  size="20"
                />
                <feather-icon
                  v-if="data.item.type === 1"
                  class="cursor-pointer"
                  icon="UserIcon"
                  size="20"
                />
                {{ resolveTypeVariant(data.value) }}
              </div>
              <div
                v-if="data.item.riskProfile && data.item.riskProfile !== '' && data.item.riskProfile !== 'NONE'"
                class="text-nowrap"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="CompassIcon"
                  size="20"
                  :style="`color:${resolveRiskProfileColor(data.item.riskProfile)};`"
                />
                {{ data.item.riskProfile === 'LOW' ? 'Laag risicoprofiel' : '' }}{{ data.item.riskProfile === 'MEDIUM' ? 'Medium risicoprofiel' : '' }}{{ data.item.riskProfile === 'HIGH' ? 'Hoog risicoprofiel' : '' }}
              </div>
            </template>
            <template #cell(documentStatus)="data">
              <b-badge :variant="documentStatusVariantParser(data.value)">
                {{ documentStatusParser(data.value) }}
              </b-badge>
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">

              <div class="text-nowrap">
                <b-link
                  :to="{ name: 'apps-relations-edit', params: { id: data.item.id }}"
                >
                  <feather-icon
                    :id="`product-insured-party-row-${data.item.id}-eye-icon`"
                    class="cursor-pointer"
                    size="16"
                    icon="EyeIcon"
                  />
                </b-link>
                <feather-icon
                  :id="`document-insured-party-row-${data.item.id}-validate-icon`"
                  class="cursor-pointer mx-1"
                  size="16"
                  icon="FolderPlusIcon"
                  style="color:#fdb813"
                  @click="openModalValidateDocumentsRelation(data.item,2)"
                />
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col
        cols="12"
      >
        <div
          v-for="(insuredParty, index) in insuredPartiesData"
          :key="insuredParty.id"
        >
          <medical-form
            :form-id="`medialForm${index}`"
            :relation="insuredParty"
            :hide-submit="true"
            :form-data="formData[insuredParty.id]"
            :disabled="true"
          />
        </div>
      </b-col>
      <b-col
        v-if="benificiaries && benificiaries.length > 0"
        cols="12"
        md="12"
      >
        <b-card
          title="Begunstigden"
        >
          <b-table
            ref="refBenificiariesListTable"
            class="position-relative"
            :items="benificiaries"
            responsive
            :fields="benificiariesTableColumns"
            primary-key="id"
            show-empty
            empty-text="Geen overeenkomende records gevonden"
          >

            <template #cell(name)="data">
              {{ +data.item.type === 1 ? getFullName(data.item) : data.item.companyName }}
            </template>

          </b-table>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          title="Documenten"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="12"
                class="d-flex align-items-rigth justify-content-end mb-1 mb-md-0"
              >
                <b-button
                  v-if="$can('update', 'order-file-upload')"
                  variant="primary"
                  @click="openUploadModal"
                >
                  Toevoegen
                </b-button>
              </b-col>
            </b-row>

          </div>
          <b-table
            ref="refFilesListTable"
            :items="files"
            responsive
            :fields="tableFilesColumns"
            primary-key="id"
            show-empty
            empty-text="Geen overeenkomende records gevonden"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
          >
            <!-- Column: Issued Date -->
            <template #cell(timestamp)="data">
              <span class="text-nowrap">
                {{ data.item.timestamp }}
              </span>
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">

              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.file.extension === 'pdf'"
                  :id="`pdf-row-${data.item.id}-preview-icon`"
                  class="cursor-pointer mx-1"
                  icon="EyeIcon"
                  size="16"
                  style="color:#fdb813"
                  @click="viewDocument(data.item.fileURL)"
                />
                <b-link
                  v-if="$can('read', 'order-file-download')"
                  :href="data.item.fileURL"
                >
                  <feather-icon
                    :id="`invoice-row-${data.item.fileID}-preview-icon`"
                    class="cursor-pointer mx-1"
                    icon="DownloadIcon"
                    size="16"
                  />
                </b-link>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-card
          title="Acties"
        >
          <b-row>
            <b-col
              v-if="$can('update', 'accept-order') && processedBy.inProcess && processedBy.inProcessTimestamp !== ''"
              cols="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary text-left"
                style="width:100%"
                class="mr-0"
                @click="setTakeCareOf(orderId, 0)"
              >
                <feather-icon
                  icon="UserIcon"
                  class="mr-50"
                />
                <span class="align-middle">Inbehandelingname vrijgeven</span>
              </b-button>
            </b-col>
            <b-col
              v-if="$can('update', 'accept-order') && !processedBy.inProcess && processedBy.inProcessTimestamp !== ''"
              cols="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                style="width:100%"
                class="mr-0 text-left"
                @click="setTakeCareOf(orderId, 1)"
              >
                <feather-icon
                  icon="UserIcon"
                  class="mr-50"
                />
                <span class="align-middle">In behandeling nemen</span>
              </b-button>
            </b-col>
            <b-col
              v-if="status === 1"
              cols="12"
              sm="12"
            >
              <div
                v-if="$can('update', 'medical-validation')"
                class="demo-inline-spacing"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="width:100%"
                  variant="primary text-left"
                  class="mr-0"
                  @click="openMedicalAllowanceModal"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Accepteer Medical Check</span>
                </b-button>
              </div>
            </b-col>
            <b-col
              v-if="status === 5"
              cols="12"
              sm="12"
            >
              <div
                v-if="$can('update', 'accept-offer')"
                class="demo-inline-spacing"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="width:100%"
                  class="mr-0"
                  variant="primary text-left"
                  @click="updateOrderStatus({items:{ status: 10 }, spinner:'showAcceptOfferSpinner'})"
                >
                  <b-spinner
                    v-if="showAcceptOfferSpinner"
                    small
                    class="mr-1"
                  />
                  <feather-icon
                    v-if="!showAcceptOfferSpinner"
                    icon="CheckCircleIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Accepteer Offerte</span>
                </b-button>
              </div>
            </b-col>
            <b-col
              v-if="status === 2"
              cols="12"
              sm="12"
            >
              <div
                v-if="$can('update', 'accept-order')"
                class="demo-inline-spacing"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="width:100%"
                  variant="primary text-left"
                  class="mr-0"
                  @click="updateOrderStatus({items:{ status: 10 }, spinner:'showAcceptOrderSpinner'} )"
                >
                  <b-spinner
                    v-if="showAcceptOrderSpinner"
                    small
                    class="mr-1"
                  />
                  <feather-icon
                    v-if="!showAcceptOrderSpinner"
                    icon="PlayCircleIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Accepteer order</span>
                </b-button>
              </div>
            </b-col>
            <b-col
              v-if="status === 1 || status === 5 || status === 2"
              cols="12"
              sm="12"
            >
              <div
                v-if="$can('update', 'cancel-offer')"
                class="demo-inline-spacing"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="width:100%"
                  class="mr-0"
                  variant="danger text-left"
                  @click="openCancelOfferModal"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Annuleren</span>
                </b-button>
              </div>
            </b-col>
            <b-col
              v-if="status === 10"
              cols="12"
              sm="12"
            >
              <div
                v-if="$can('update', 'cancel-offer')"
                class="demo-inline-spacing"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="width:100%"
                  class="mr-0"
                  variant="warning text-left"
                  @click="openModalRoyementOffer"
                >
                  <feather-icon
                    icon="MinusCircleIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Aanvraag royement</span>
                </b-button>
              </div>
            </b-col>
            <b-col
              v-if="status === 10"
              cols="12"
              sm="12"
            >
              <div
                v-if="$can('read', 'claim')"
                class="demo-inline-spacing"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="width:100%"
                  class="mr-0"
                  variant="danger text-left"
                  @click="requestClaim"
                >
                  <feather-icon
                    icon="StopCircleIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Claim aanvragen</span>
                </b-button>
              </div>
            </b-col>
            <b-col
              v-if="status === 10"
              cols="12"
              sm="12"
            >
              <div
                v-if="$can('read', 'add-on')"
                class="demo-inline-spacing"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="width:100%"
                  class="mr-0 text-left"
                  variant="success"
                  @click="showAddOnModal"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Suppletie</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        v-if="$can('read', 'prolongatie')"
        cols="12"
        md="12"
      >
        <b-overlay
          :show="showProlongationsSpinner"
          rounded="sm"
        >
          <b-card
            title="Prolongatie"
          >
            <b-table
              class="position-relative"
              :items="prolongations"
              responsive
              :fields="tableColumnsProlongations"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
            >
              <!-- Column: Id -->
              <template #cell(id)="data">
                #{{ data.value }}
              </template>

              <template #cell(orderID)="data">
                <b-link
                  :id="`order-detail-view-action-row-${data.value}`"
                  :to="{ name: 'app-order-detail', params: { id: data.value }}"
                >
                  #{{ data.value }}
                </b-link>
              </template>

              <template #cell(status)="data">
                <b-avatar
                  :id="`invoice-row-${data.item.id}`"
                  size="32"
                  :variant="`light-${resolveInvoiceTypeVariant(data.item.status).variant}`"
                >
                  <feather-icon
                    :icon="resolveInvoiceTypeVariant(data.item.status).icon"
                  />
                </b-avatar>
                <b-tooltip
                  :target="`invoice-row-${data.item.id}`"
                  placement="top"
                >
                  <p class="mb-0">
                    {{ resolveInvoiceTypeVariant(data.item.status).tooltip }}
                  </p>
                </b-tooltip>
              </template>

              <template #cell(order)="data">
                <div vertical-align="center">
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ getFullName(data.item.order.policyHolderRelation) }}
                  </span>
                  <small class="text-muted">{{ formatDate(data.item.order.policyHolderRelation.birthDate) }}</small>
                </div>
              </template>

              <template #cell(company)="data">
                {{ data.item.company.name }}
              </template>

              <template #cell(type)="data">
                {{ data.item.file.fileName }}
              </template>

              <template #cell(amount)="data">
                {{ data.item.total }} {{ data.item.currency }}
              </template>

              <template #cell(timestamp)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{ formatDateTime(data.value) }}</span>
                </div>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <feather-icon
                    :id="`pdf-row-${data.item.id}-preview-icon`"
                    class="cursor-pointer mx-1"
                    icon="EyeIcon"
                    size="16"
                    style="color:#fdb813"
                    @click="viewDocument(data.item.fileURL)"
                  />
                  <b-link
                    :href="data.item.fileURL"
                    target="_blank"
                  >
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-download-icon`"
                      class="cursor-pointer mx-1"
                      icon="DownloadIcon"
                      size="16"
                      style="color:#82868b"
                    />
                    <b-tooltip
                      :target="`invoice-row-${data.item.id}-download-icon`"
                      placement="top"
                    >
                      <p class="mb-0">
                        Factuur downloaden
                      </p>
                    </b-tooltip>
                  </b-link>
                  <b-link
                    @click="invoicedPaid(data.item.id)"
                  >
                    <feather-icon
                      v-if="data.item.status === 1 && $can('update', 'invoice')"
                      :id="`invoice-row-${data.item.id}-status-icon`"
                      class="cursor-pointer"
                      size="16"
                      icon="CreditCardIcon"
                      style="color:#28c76f"
                    />
                    <b-tooltip
                      v-if="$can('update', 'invoice')"
                      :target="`invoice-row-${data.item.id}-status-icon`"
                      placement="top"
                    >
                      <p class="mb-0">
                        Status aanpassen naar betaald
                      </p>
                    </b-tooltip>
                  </b-link>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        v-if="$can('read', 'invoice')"
        cols="12"
        md="12"
      >
        <b-overlay
          :show="showInvoiceSpinner"
          rounded="sm"
        >
          <b-card
            title="Facturen"
          >
            <b-table
              class="position-relative"
              :items="invoices"
              responsive
              :fields="tableInvoicesColumns"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
            >
              <!-- Column: Id -->
              <template #cell(id)="data">
                #{{ data.value }}
              </template>

              <template #cell(orderID)="data">
                <b-link
                  :id="`order-detail-view-action-row-${data.value}`"
                  :to="{ name: 'app-order-detail', params: { id: data.value }}"
                >
                  #{{ data.value }}
                </b-link>
              </template>

              <template #cell(status)="data">
                <b-avatar
                  :id="`invoice-row-${data.item.id}`"
                  size="32"
                  :variant="`light-${resolveInvoiceTypeVariant(data.item.status).variant}`"
                >
                  <feather-icon
                    :icon="resolveInvoiceTypeVariant(data.item.status).icon"
                  />
                </b-avatar>
                <b-tooltip
                  :target="`invoice-row-${data.item.id}`"
                  placement="top"
                >
                  <p class="mb-0">
                    {{ resolveInvoiceTypeVariant(data.item.status).tooltip }}
                  </p>
                </b-tooltip>
              </template>

              <template #cell(order)="data">
                <div vertical-align="center">
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ getFullName(data.item.order.policyHolderRelation) }}
                  </span>
                  <small class="text-muted">{{ formatDate(data.item.order.policyHolderRelation.birthDate) }}</small>
                </div>
              </template>

              <template #cell(company)="data">
                {{ data.item.company.name }}
              </template>

              <template #cell(type)="data">
                {{ data.item.file.fileName }}
              </template>

              <template #cell(amount)="data">
                {{ data.item.total }} {{ data.item.currency }}
              </template>

              <template #cell(timestamp)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{ formatDateTime(data.value) }}</span>
                </div>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <feather-icon
                    :id="`pdf-row-${data.item.id}-preview-icon`"
                    class="cursor-pointer mx-1"
                    icon="EyeIcon"
                    size="16"
                    style="color:#fdb813"
                    @click="viewDocument(data.item.fileURL)"
                  />
                  <b-link
                    :href="data.item.fileURL"
                    target="_blank"
                  >
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-download-icon`"
                      class="cursor-pointer mx-1"
                      icon="DownloadIcon"
                      size="16"
                      style="color:#82868b"
                    />
                    <b-tooltip
                      :target="`invoice-row-${data.item.id}-download-icon`"
                      placement="top"
                    >
                      <p class="mb-0">
                        Factuur downloaden
                      </p>
                    </b-tooltip>
                  </b-link>
                  <b-link
                    @click="invoicedPaid(data.item.id)"
                  >
                    <feather-icon
                      v-if="data.item.status === 1 && $can('update', 'invoice')"
                      :id="`invoice-row-${data.item.id}-status-icon`"
                      class="cursor-pointer"
                      size="16"
                      icon="CreditCardIcon"
                      style="color:#28c76f"
                    />
                    <b-tooltip
                      v-if="$can('update', 'invoice')"
                      :target="`invoice-row-${data.item.id}-status-icon`"
                      placement="top"
                    >
                      <p class="mb-0">
                        Status aanpassen naar betaald
                      </p>
                    </b-tooltip>
                  </b-link>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        v-if="$can('read', 'receipt')"
        cols="12"
        md="12"
      >
        <b-overlay
          :show="showReceiptSpinner"
          rounded="sm"
        >
          <b-card
            title="Kwitanties"
          >
            <b-table
              class="position-relative"
              :items="receipts"
              responsive
              :fields="tableReceiptsColumns"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
            >
              <!-- Column: Id -->
              <template #cell(id)="data">
                #{{ data.value }}
              </template>

              <template #cell(orderID)="data">
                <b-link
                  :id="`order-detail-view-action-row-${data.value}`"
                  :to="{ name: 'app-order-detail', params: { id: data.value }}"
                >
                  #{{ data.value }}
                </b-link>
              </template>

              <template #cell(order)="data">
                <div vertical-align="center">
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ getFullName(data.item.order.policyHolderRelation) }}
                  </span>
                  <small class="text-muted">{{ formatDate(data.item.order.policyHolderRelation.birthDate) }}</small>
                </div>
              </template>

              <template #cell(company)="data">
                {{ data.item.order.user.company.name }}
              </template>

              <template #cell(type)="data">
                {{ data.item.file.fileName }}
              </template>
              <!-- Column: Id -->
              <template #cell(id)="data">
                #{{ data.value }}
              </template>

              <template #cell(orderID)="data">
                <b-link
                  :id="`order-detail-view-action-row-${data.value}`"
                  :to="{ name: 'app-order-detail', params: { id: data.value }}"
                >
                  #{{ data.value }}
                </b-link>
              </template>

              <template #cell(type)="data">
                {{ data.item.file.fileName }}
              </template>
              <template #cell(amount)="data">
                {{ data.item.amount }} {{ data.item.currency }}
              </template>

              <template #cell(timestamp)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{ formatDateTime(data.value) }}</span>
                </div>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <feather-icon
                    :id="`pdf-row-${data.item.id}-preview-icon`"
                    class="cursor-pointer mx-1"
                    icon="EyeIcon"
                    size="16"
                    style="color:#fdb813"
                    @click="viewDocument(data.item.fileURL)"
                  />
                  <b-link
                    :href="data.item.fileURL"
                    target="_blank"
                  >
                    <feather-icon
                      :id="`receipt-row-${data.item.id}-download-icon`"
                      class="cursor-pointer mx-1"
                      icon="DownloadIcon"
                      size="16"
                      style="color:#82868b"
                    />
                    <b-tooltip
                      :target="`receipt-row-${data.item.id}-download-icon`"
                      placement="top"
                    >
                      <p class="mb-0">
                        Kwitantie downloaden
                      </p>
                    </b-tooltip>
                  </b-link>
                </div>
              </template>
              <template #cell(amount)="data">
                {{ data.item.amount }} {{ data.item.currency }}
              </template>

              <template #cell(timestamp)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{ formatDateTime(data.value) }}</span>
                </div>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <feather-icon
                    :id="`pdf-row-${data.item.id}-preview-icon`"
                    class="cursor-pointer mx-1"
                    icon="EyeIcon"
                    size="16"
                    style="color:#fdb813"
                    @click="viewDocument(data.item.fileURL)"
                  />
                  <b-link
                    :href="data.item.fileURL"
                    target="_blank"
                  >
                    <feather-icon
                      :id="`receipt-row-${data.item.id}-download-icon`"
                      class="cursor-pointer mx-1"
                      icon="DownloadIcon"
                      size="16"
                      style="color:#82868b"
                    />
                    <b-tooltip
                      :target="`receipt-row-${data.item.id}-download-icon`"
                      placement="top"
                    >
                      <p class="mb-0">
                        Kwitantie downloaden
                      </p>
                    </b-tooltip>
                  </b-link>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card
          title="Overzicht Notitie(s)"
        >
          <b-row class="match-height">
            <b-col
              cols="12"
            >
              <b-row>
                <b-col
                  v-if="$can('read', 'order-note')"
                  cols="12"
                >
                  <ul
                    v-if="notes.length > 0"
                    class="app-timeline mb-2"
                  >
                    <li
                      v-for="note in notes"
                      :key="`note${note.id}`"
                      class="timeline-item timeline-variant-primary"
                    >
                      <div class="timeline-item-point" />

                      <slot>
                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                          <h6
                            v-if="note.user && note.user.fullname"
                            v-text="note.user.fullname"
                          />
                          <small
                            class="timeline-item-time text-nowrap text-muted"
                            v-text="note.timestamp"
                          />
                        </div>
                        <p
                          class="mb-0"
                          v-text="note.note"
                        />
                      </slot>
                    </li>
                  </ul>
                </b-col>

              </b-row>
              <b-row>
                <b-col
                  cols="12"
                >
                  <validation-observer
                    ref="refIsOrderNoteObserver"
                  >
                    <b-form>
                      <validation-provider
                        #default="validationContext"
                        name="note"
                        rules="required"
                      >
                        <b-form-group
                          label-for="note"
                        >
                          <label>Notitie</label>
                          <b-form-textarea
                            id="note"
                            v-model="note.note"
                            rows="2"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-form>
                  </validation-observer>
                </b-col>
                <b-col
                  cols="12"
                  class="d-flex align-items-rigth justify-content-end mt-2 mb-2 mb-md-0"
                >
                  <b-button
                    v-if="$can('update', 'order-note')"
                    variant="primary"
                    @click="saveNewNote"
                  >
                    Toevoegen
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card
          title="Historie"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <app-timeline>
                <app-timeline-item
                  v-for="activitie in activities"
                  :key="`activitie${activitie.id}`"
                  :title="resolveActivitieVariant(activitie.type).title"
                  :subtitle="activitie.description"
                  :icon="resolveActivitieVariant(activitie.type).icon"
                  :time="activitie.timestamp"
                  :variant="resolveActivitieVariant(activitie.type).variant"
                />
              </app-timeline>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <b-row>
            <b-col
              cols="6 text-left"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="back()"
              >
                <span>Terug</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard, BCardText,
  BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BLink, BMedia,
  BRow, BSpinner, BTable, BBadge, BAlert, BTooltip, BOverlay,
} from 'bootstrap-vue'
import {
  required, integer, decimal, between, requiredIf, minValue, length,
} from '@core/utils/validations/validations'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import orderStoreModule from '@/views/pages/order/orderStoreModule'
import { onUnmounted, ref } from '@vue/composition-api'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'
import useOrderList from '@/views/pages/order/useOrderList'
import { avatarText } from '@core/utils/filter'
import { formatDate, formatDateTime } from '@core/utils/utils'
import medicalForm from '@/views/pages/order-flow/forms/medical/MedicalForm.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import AppRelationDocuments from '@core/components/app-relation-documents/AppRelationDocuments.vue'
import PdfViewer from '@/views/pages/pdf-viewer/PdfViewer.vue'
import { heightFade } from '@core/directives/animations'
import useRelationsList from '@/views/pages/relations/useRelationsList'

export default {
  components: {
    PdfViewer,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BMedia,
    BAvatar,
    medicalForm,
    BSpinner,
    BCardText,
    AppTimeline,
    AppTimelineItem,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormGroup,
    BForm,
    BFormInput,
    BBadge,
    flatPickr,
    AppRelationDocuments,
    BAlert,
    BTooltip,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      required,
      integer,
      decimal,
      between,
      minValue,
      requiredIf,
      length,
      receipts: [],
      invoices: [],
      showReceiptSpinner: true,
      showInvoiceSpinner: true,
      showAlert: true,
      insuredPartiesData: [],
      polisInformation: {
        insuredAmount: 0,
        currency: '',
        contractDuration: '',
        paymentFrequency: '',
        startDate: '',
        paymentMethod: '',
        collectiveID: null,
        employerContributionPercentage: null,
        employmentDate: '',
        partnerPensionType: null,
        product: {},
        disbarmentNote: '',
        disbarmentRequestDate: '',
        disbarmentRequestNote: '',
      },
      selectedProduct: {},
      benificiaries: [],
      polisHolderData: [],
      files: [],
      formData: [],
      orderId: '',
      uploadLoader: false,
      status: 0,
      notes: [],
      note: {
        type: 1,
        note: '',
      },
      activities: [],
      cancelOfferObject: {
        status: 91,
        declineReason: '',
      },
      medicalAllowanceObject: {
        medicalAllowancePercentage: null,
        status: 5,
      },
      royementOfferObject: {
        disbarmentRequestNote: '',
        disbarmentRequestDate: '',
        status: 93,
      },
      showMedicalAllowanceSpinner: false,
      showCancelOfferSpinner: false,
      showAcceptOrderSpinner: false,
      showAcceptOfferSpinner: false,
      showRoyementOfferSpinner: false,
      route: '',
      royementDateConfig: {
        minDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      selectedRelationDocumentValidation: {
        id: null,
        files: [],
        type: null,
      },
      selectedRelationDocumentValidationType: null,
      processedBy: {
        inProcess: null,
        processedBy: null,
        processedByName: '',
        inProcessTimestamp: '',
      },
      addOnData: {
        id: null,
        newContractDuration: null,
        newInsuredAmount: null,
      },
      showAddOnSpinner: false,
      showProlongationsSpinner: false,
      prolongations: [],
      totalProlongations: null,
    }
  },
  computed: {
    validateSuppletion() {
      if (this.addOnData.newContractDuration || this.addOnData.newInsuredAmount) {
        return true
      }
      return false
    },
  },
  mounted() {
    this.refetchOrderDetailData()
  },
  created() {
    this.getInvoices()
    this.getReceipts()
    this.getProlongations()
  },
  methods: {
    requestClaim() {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, claim aanvragen!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-order-detail/updateOrder', { id: this.orderId, status: 110 })
            .then(() => {
              this.refetchOrderDetailData()
              this.$swal({
                icon: 'success',
                title: 'Claim!',
                text: 'De claim is aangevraagt.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Claim aanvragen',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: `${errorAndSuccesDefaultMessage.saveDataError}`,
                },
              })
            })
        }
      })
    },
    showAddOnModal() {
      this.$refs.modalAddOn.show()
    },
    handleAddOn() {
      this.addOnData.id = this.$router.currentRoute.params.id
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-order-detail/updateOrder', this.addOnData)
            .then(() => {
              this.refetchOrderDetailData()
              this.$refs.modalAddOn.hide()
              this.$swal({
                icon: 'success',
                title: 'Suppletie!',
                text: 'De suppletie aanvraag is met succes verwerkt.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {
              this.$refs.modalAddOn.hide()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Suppletie',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: `${errorAndSuccesDefaultMessage.saveDataError}`,
                },
              })
            })
        }
      })
    },
    fetchStatistics() {
      store
        .dispatch('app-menu-statistics/fetchMenuStatistics')
        .then(response => {
          this.$store.commit('app/UPDATE_MENU_STATISTICS', response.data)
        })
    },
    getProlongations() {
      if (this.$ability.can('read', 'prolongatie')) {
        this.showProlongationsSpinner = true
        store
          .dispatch('app-order-detail/fetchProlongations', {
            sortDesc: false,
            sortBy: 'id',
            orderID: this.$router.currentRoute.params.id,
          })
          .then(response => {
            const { items, _meta } = response.data

            this.prolongations = items
            this.totalProlongations = _meta.totalCount
            this.showProlongationsSpinner = false
          })
          .catch(() => {
            this.showProlongationsSpinner = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error bij het ophalen van de prolongations',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    getInvoices() {
      if (this.$ability.can('read', 'invoice')) {
        store
          .dispatch('app-order-detail/fetchInvoices', {
            sortDesc: true,
            sortBy: 'id',
            orderID: this.$router.currentRoute.params.id,
          })
          .then(response => {
            this.invoices = response.data.items
            this.showInvoiceSpinner = false
          })
          .catch(() => {
            this.showInvoiceSpinner = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ophalen facturen',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
              },
            })
          })
      }
    },
    getReceipts() {
      if (this.$ability.can('read', 'receipt')) {
        store
          .dispatch('app-order-detail/fetchReceipts', {
            sortDesc: true,
            sortBy: 'id',
            orderID: this.$router.currentRoute.params.id,
          })
          .then(response => {
            this.receipts = response.data.items
            this.showReceiptSpinner = false
          })
          .catch(() => {
            this.showReceiptSpinner = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ophalen kwitanties',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
              },
            })
          })
      }
    },
    back() {
      if (this.route === '') {
        this.$router.go(-1)
      }

      if (this.route.length > 0) {
        this.$router.replace({ path: `/order/overview/${this.route}` })
      }
    },
    viewDocument(url) {
      this.$store.commit('app/UPDATE_PDF_URL', url)
      this.$refs.modalPdfViewer.show()
    },
    documentStatusParser(status) {
      switch (status) {
        case 1:
          return 'Volledig & Gevalideerd'
        case 2:
          return 'Volledig, Wacht op validatie'
        case 3:
          return 'Niet volledig'
        default:
          return 'Onbekend'
      }
    },
    documentStatusVariantParser(status) {
      switch (status) {
        case 1:
          return 'success'
        case 2:
          return 'warning'
        case 3:
          return 'danger'
        default:
          return 'danger'
      }
    },
    openModalValidateDocumentsRelation(data, type) {
      this.selectedRelationDocumentValidation = data
      this.selectedRelationDocumentValidationType = type
      this.$refs.modalValidateDocumentsRelation.show()
    },
    openMedicalAllowanceModal() {
      this.$refs.modalMedicalAllowance.show()
    },
    medicalAllowance() {
      this.updateOrderStatus({ items: this.medicalAllowanceObject, spinner: 'showMedicalAllowanceSpinner' })
    },
    openCancelOfferModal() {
      this.$refs.modalCancelOffer.show()
    },
    cancelOffer() {
      this.updateOrderStatus({ items: this.cancelOfferObject, spinner: 'showCancelOfferSpinner' })
    },
    openModalRoyementOffer() {
      this.$refs.modalRoyementOffer.show()
    },
    handelRoyementRequest() {
      this.updateOrderStatus({ items: this.royementOfferObject, spinner: 'showRoyementOfferSpinner' })
    },
    updateOrderStatus(data) {
      if (data.spinner === 'showAcceptOfferSpinner') {
        this.showAcceptOfferSpinner = true
      }
      if (data.spinner === 'showAcceptOrderSpinner') {
        this.showAcceptOrderSpinner = true
      }
      if (data.spinner === 'showCancelOfferSpinner') {
        this.showCancelOfferSpinner = true
      }
      if (data.spinner === 'showMedicalAllowanceSpinner') {
        this.showMedicalAllowanceSpinner = true
      }
      if (data.spinner === 'showRoyementOfferSpinner') {
        this.showRoyementOfferSpinner = true
      }

      data.items.id = this.$router.currentRoute.params.id

      store.dispatch('app-order-detail/patchOrder', { data: data.items })
        .then(res => {
          if (res.data.order.status === 5) {
            this.route = '5'
          }

          if (res.data.order.status === 1 || res.data.order.status === 2) {
            this.route = '1,2'
          }

          if (res.data.order.status === 90 || res.data.order.status === 91 || res.data.order.status === 92) {
            this.route = '90,91'
          }

          if (res.data.order.status === 92) {
            this.route = '92'
          }

          if (res.data.order.status === 10) {
            this.route = '10'
          }

          this.showAcceptOfferSpinner = false
          this.showAcceptOrderSpinner = false
          this.showCancelOfferSpinner = false
          this.showMedicalAllowanceSpinner = false
          this.showRoyementOfferSpinner = false
          this.$refs.modalMedicalAllowance.hide()
          this.$refs.modalCancelOffer.hide()
          this.$refs.modalRoyementOffer.hide()
          this.refetchOrderDetailData()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Order',
              icon: 'AlertTriangleIcon',
              variant: 'succes',
              text: 'De order is met succes gewijzigd',
            },
          })
        })
        .catch(() => {
          this.showAcceptOfferSpinner = false
          this.showAcceptOrderSpinner = false
          this.showCancelOfferSpinner = false
          this.showMedicalAllowanceSpinner = false
          this.showRoyementOfferSpinner = false
          this.$refs.modalMedicalAllowance.hide()
          this.$refs.modalCancelOffer.hide()
          this.$refs.modalRoyementOffer.hide()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Order',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${errorAndSuccesDefaultMessage.updateDataError}`,
            },
          })
        })
    },
    refetchOrderDetailData() {
      store.dispatch('app-order-detail/fetchOrder', { id: router.currentRoute.params.id })
        .then(response => {
          this.fetchStatistics()
          this.polisHolderData = []
          this.orderId = response.data.id
          this.status = response.data.status
          this.selectedProduct = response.data.product
          this.insuredPartiesData = response.data.insuredRelations
          this.benificiaries = response.data.benificiaries ? response.data.benificiaries : []
          this.notes = response.data.notes ? response.data.notes : []
          this.activities = response.data.activities ? response.data.activities : []
          this.formData = response.data.formattedMedicalForm
          this.polisHolderData.push(response.data.policyHolderRelation)
          this.polisInformation = {
            insuredAmount: response.data.insuredAmount,
            currency: response.data.currency,
            contractDuration: response.data.contractDuration,
            paymentFrequency: response.data.paymentFrequency,
            startDate: response.data.startDate,
            paymentMethod: response.data.paymentMethod,
            premiumFactor: response.data.premiumFactor,
            premiumAllowancePercentage: response.data.premiumAllowancePercentage,
            canAutoAccept: response.data.canAutoAccept,
            policyCost: response.data.policyCost,
            premium: response.data.premium,
            grossPremium: response.data.grossPremium,
            discount: response.data.discount,
            extraDiscount: response.data.extraDiscount,
            yearLatentIP: response.data.yearLatentIP,
            yearLatentPP: response.data.yearLatentPP,
            yearLatentWZ: response.data.yearLatentWZ,
            timestamp: response.data.timestamp,
            notes: response.data.notes,
            user: response.data.user.fullname,
            productType: response.data.product.type,
            companyName: response.data.user.company.name,
            status: response.data.status,
            collectiveID: response.data.collectiveID ? response.data.collectiveID : null,
            employerContributionPercentage: response.data.employerContributionPercentage ? response.data.employerContributionPercentage : null,
            employmentDate: response.data.employmentDate ? response.data.employmentDate : '',
            partnerPensionType: response.data.partnerPensionType ? response.data.partnerPensionType : null,
            product: response.data.product,
            disbarmentNote: response.data.disbarmentNote,
            disbarmentRequestDate: response.data.disbarmentRequestDate,
            disbarmentRequestNote: response.data.disbarmentRequestNote,
          }
          this.files = response.data.files
          this.processedBy.inProcess = response.data.inProcess
          this.processedBy.processedByName = response.data.processedByName
          this.processedBy.processedBy = response.data.processedBy
          this.processedBy.inProcessTimestamp = response.data.inProcessTimestamp

          // Update selected relation validate documents

          if (this.selectedRelationDocumentValidationType === 1 && this.selectedRelationDocumentValidation.id) {
            const result = this.polisHolderData.filter(relation => relation.id === this.selectedRelationDocumentValidation.id)[0]
            this.selectedRelationDocumentValidation = result
          }

          if (this.selectedRelationDocumentValidationType === 2 && this.selectedRelationDocumentValidation.id) {
            const result = this.insuredPartiesData.filter(relation => relation.id === this.selectedRelationDocumentValidation.id)[0]
            this.selectedRelationDocumentValidation = result
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ophalen Order',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
            },
          })
        })
    },
    getFullName(data) {
      let fullName = ''

      if (data) {
        if (data.middleName && data.middleName.length > 0) {
          fullName = `${data.firstName} ${data.middleName} ${data.lastName}`
        } else {
          fullName = `${data.firstName} ${data.lastName}`
        }
      }

      return fullName
    },
    openUploadModal() {
      this.$refs.modalUpload.show()
    },
    uploadDocument() {
      this.uploadLoader = true
      const file = document.querySelector('input[type=file]').files[0]
      if (file.size > 10485760) {
        this.uploadLoader = false
        this.$swal({
          icon: 'error',
          title: 'Afbeeldingsgrootte',
          text: 'De maximale afbeeldinggrootte is 10mb. Het huidige bestand is te groot.',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      } else {
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            const fileUploadData = {
              file: reader.result,
              type: 'OrderFile',
              name: file.name,
              orderID: this.orderId,
            }

            store.dispatch('app-order-detail/uploadImage', fileUploadData)
              .then(() => {
                this.uploadLoader = false
                this.$refs.modalUpload.hide()
                this.refetchOrderDetailData()
              })
              .catch(() => {
                this.uploadLoader = false
                this.$swal({
                  icon: 'error',
                  title: 'Uploaden',
                  text: `${errorAndSuccesDefaultMessage.uploadDataError}`,
                  customClass: {
                    confirmButton: 'btn btnOX-danger',
                  },
                })
              })
          }, false,
        )

        reader.readAsDataURL(file)
      }
    },
    statusParser(status) {
      switch (status) {
        case 1:
          return 'Medische Check'
        case 2:
          return 'Accepteer Order'
        case 5:
          return 'Accepteer offerte'
        case 10:
          return 'Actief'
        case 80:
          return 'Verlengd'
        case 90:
          return 'Offerte Verlopen'
        case 91:
          return 'Afgewezen'
        case 92:
          return 'Royement geaccepteerd'
        case 93:
          return 'Royement aangevraagd'
        default:
          return 'Onbekend'
      }
    },
    async saveNewNote() {
      await this.$refs.refIsOrderNoteObserver.validate().then(valid => {
        if (valid) {
          this.note.orderID = this.orderId
          store.dispatch('app-order-detail/addNote', this.note)
            .then(() => {
              this.note.note = ''
              this.$refs.refIsOrderNoteObserver.reset()
              this.refetchOrderDetailData()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Notitie',
                  icon: 'AlertTriangleIcon',
                  variant: 'succes',
                  text: 'Uw notitie is opgeslagen',
                },
              })
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Notitie toevoegen',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: `${errorAndSuccesDefaultMessage.saveDataError}`,
                },
              })
            })
        }
      })
    },
    setTakeCareOf(orderID, type) {
      let processed = -1
      if (type === 1) {
        processed = 1
      }

      store.dispatch('app-order-detail/patchOrder', { data: { id: orderID, inProcess: processed } })
        .then(() => {
          this.refetchOrderDetailData()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'In behandeling nemen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${errorAndSuccesDefaultMessage.updateDataError}`,
            },
          })
        })
    },
    invoicedPaid(id) {
      store.dispatch('app-order-detail/updateInvoice', id)
        .then(() => {
          this.getInvoices()
          this.$swal({
            icon: 'success',
            title: 'Factuur status!',
            text: 'De status van de factuur is aangepast.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Factuur status!',
            text: `${errorAndSuccesDefaultMessage.saveDataError}`,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
  },
  setup() {
    const refIsOrderNoteObserver = ref(null)
    const ORDER_DETAIL_MODULE_NAME = 'app-order-detail'

    // Register module
    if (!store.hasModule(ORDER_DETAIL_MODULE_NAME)) store.registerModule(ORDER_DETAIL_MODULE_NAME, orderStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORDER_DETAIL_MODULE_NAME)) store.unregisterModule(ORDER_DETAIL_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    const {
      resolveRiskProfileColor,
    } = useRelationsList()

    const {
      refFilesListTable,
      refetchData,
      tableFilesColumns,
      isSortDirDesc,
      insuredPartiesTableColumns,
      refInsuredPartiesListTable,
      refPolisHolderListTable,
      polisHolderTableColumns,
      refBenificiariesListTable,
      benificiariesTableColumns,
      resolveActivitieVariant,
      resolveTypeVariant,
      tableInvoicesColumns,
      resolveInvoiceTypeVariant,
      tableReceiptsColumns,
      tableColumnsProlongations,
    } = useOrderList()

    return {
      refFilesListTable,
      refetchData,
      tableFilesColumns,
      isSortDirDesc,
      insuredPartiesTableColumns,
      refInsuredPartiesListTable,
      refPolisHolderListTable,
      polisHolderTableColumns,
      refBenificiariesListTable,
      benificiariesTableColumns,
      avatarText,
      refFormObserver,
      getValidationState,
      resetForm,
      refIsOrderNoteObserver,
      resolveActivitieVariant,
      resolveTypeVariant,
      formatDate,
      tableInvoicesColumns,
      formatDateTime,
      resolveInvoiceTypeVariant,
      tableReceiptsColumns,
      tableColumnsProlongations,
      resolveRiskProfileColor,
    }
  },
}
</script>
<style>
.signature {
  border: solid 2px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
  radial-gradient(circle at top left, #fdb813, #d31145);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';

$timeline-border-color: $border-color;

/* Generate:
*  Apply background color to dot
*/
@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type== 'base' {
      .timeline-variant-#{$color_name} {
        &.timeline-item-fill-border-#{$color_name} {
          border-color: $color_value !important;
          &:last-of-type {
            &:after {
              background: linear-gradient($color_value, $white);
            }
          }
        }
        .timeline-item-point {
          background-color: $color_value;

          &:before {
            background-color: rgba($color_value, 0.12);
          }
        }

        .timeline-item-icon {
          color: $color_value;
          border: 1px solid $color_value;
        }
      }
    }
  }
}

.app-timeline {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  margin-left: 1rem;
}

.timeline-item {
  padding-left: 2.5rem;
  position: relative;
  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-left: 1px solid $timeline-border-color;
  }

  // This gives shade to last timeline-item but isn't that revolutionary
  &:last-of-type {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient($timeline-border-color, $white);
    }
  }

  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: 0;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: '';
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -12px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: $white;
    z-index: 1;
  }
}

// *===============================================---*
// *--------- Dark Layout ---------------------------------------*
// *===============================================---*

.dark-layout {
  .timeline-item {
    &:last-of-type {
      &:after {
        background: linear-gradient($theme-dark-border-color, $theme-dark-card-bg);
      }
    }
    &:not(:last-of-type) {
      border-left-color: $theme-dark-border-color;
    }

    .timeline-item-icon {
      background-color: $theme-dark-card-bg;
    }
  }
}

.orderInfo{
    tr{
      th{
        min-width: 155px;
      }
    }
}
</style>

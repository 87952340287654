import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    uploadRelationDocument(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}file/upload`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    validateDocument(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${axiosDefaultConfig.backendEndPoint}file/validate`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

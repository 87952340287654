import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default function useRelationsList() {
  // Use toast
  const toast = useToast()

  const refRelationsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Relatie', key: 'relation', sortable: false },
    { label: 'Profiel', key: 'type', sortable: true },
    { label: 'Telefoonnummer', key: 'phone', sortable: true },
    // { label: 'Plaats', key: 'city', sortable: true },
    { label: 'District', key: 'district', sortable: true },
    { label: 'Documentstatus', key: 'documentStatus', sortable: false },
    { label: 'Acties', key: 'actions', tdClass: 'actions' },
  ]
  const perPage = ref(10)
  const totalRelations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const showOverlay = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refRelationsListTable.value ? refRelationsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRelations.value,
    }
  })

  const refetchData = () => {
    refRelationsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchRelations = (ctx, callback) => {
    store
      .dispatch('apps-relations-list/fetchRelations', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalRelations.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Relationss list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const legalFormOptions = [
    { label: 'Naamloze Vennootschap', value: 'Naamloze Vennootschap' },
    { label: 'Eenmanszaak', value: 'Eenmanszaak' },
    { label: 'V.O.F.', value: 'V.O.F.' },
    { label: 'C.V.', value: 'C.V.' },
    { label: 'B.V.', value: 'B.V.' },
    { label: 'Stichting', value: 'Stichting' },
    { label: 'Vereniging', value: 'Vereniging' },
    { label: 'Anders', value: 'Anders' },
  ]
  const countryOptions = [
    { label: 'Suriname', value: 'SR' },
  ]

  const districts = [
    {
      label: 'SR',
      value: [
        {
          label: 'Brokopondo',
          value: 'Brokopondo',
        },
        {
          label: 'Commewijne',
          value: 'Commewijne',
        },
        {
          label: 'Coronie',
          value: 'Coronie',
        },
        {
          label: 'Marowijne',
          value: 'Marowijne',
        },
        {
          label: 'Nickerie',
          value: 'Nickerie',
        },
        {
          label: 'Para',
          value: 'Para',
        },
        {
          label: 'Paramaribo',
          value: 'Paramaribo',
        },
        {
          label: 'Saramacca',
          value: 'Saramacca',
        },
        {
          label: 'Sipaliwini',
          value: 'Sipaliwini',
        },
        {
          label: 'Wanica',
          value: 'Wanica',
        },
      ],
    },
  ]

  // Change district on different list.js select
  const resolveDistrictForCountry = countryCode => districts.filter(arr => arr.label === countryCode)[0].value

  // Initial set districts
  const districtOptions = resolveDistrictForCountry('SR')

  const resolveTypeVariant = status => {
    if (status === 1) return 'Particulier'
    if (status === 2) return 'Zakelijk'
    return ''
  }

  const resolveRiskProfileColor = type => {
    if (type === 'LOW') return '#28c76f'
    if (type === 'MEDIUM') return '#ff9f43'
    if (type === 'HIGH') return '#ea5455'
    return '#fdb813'
  }

  const idTypeOptions = [
    { label: 'Paspoort', value: 'Paspoort' },
    { label: 'Rijbewijs', value: 'Rijbewijs' },
    { label: 'IDkaart', value: 'IDkaart' },
  ]

  const martialOptions = [
    { label: 'Gehuwd', value: 'Gehuwd' },
    { label: 'Ongehuwd', value: 'Ongehuwd' },
    { label: 'Concubinaat', value: 'Concubinaat' },
  ]

  return {
    fetchRelations,
    tableColumns,
    perPage,
    currentPage,
    totalRelations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRelationsListTable,
    resolveDistrictForCountry,
    refetchData,
    resolveRiskProfileColor,
    countryOptions,
    districtOptions,
    legalFormOptions,
    idTypeOptions,
    resolveTypeVariant,
    showOverlay,
    martialOptions,
  }
}

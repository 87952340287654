<template :id="formId">
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <b-form
      id="medicalForm"
      ref="printMe"
      class="list-view product-checkout"
      @submit.prevent="handleSubmit(onSubmit)"
    >

      <!-- Left Form -->
      <b-card no-body>
        <b-card-actions
          ref="cardActions"
          :title="`<i class='wizard-icon feather ${formStatusIcon} mr-1'></i>Medisch formulier <small>${getFullName(relation)}</small>`"
          action-collapse
          :collapsed="true"
        >
          <b-card-text
            v-if="disabled"
            class="d-flex align-items-center justify-content-end"
          >
            <b-link
              @click="print"
            >
              <feather-icon
                icon="PrinterIcon"
                size="18"
                class="mr-50"
              />
            </b-link>
          </b-card-text>
          <b-card-header class="flex-column align-items-start">
            <b-card-text class="text-muted mt-0">
              Vul dit formulier juist en naar waarheid in, verzijwiging en/of verkeerde of onvoldoende informatie kan ervoor zorgen dat de verzekeringsovereenkomst nietig verklaard kan worden.
            </b-card-text>
          </b-card-header>
          <b-card-body>
            <!-- Question 1 -->
            <b-row style="margin-bottom: 10px">
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Lengte (in cm)</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q1"
                      rules="required|integer"
                    >
                      <b-form-group
                        label-for="q1"
                      >
                        <b-form-input
                          id="q1"
                          v-model="medicalForm.q1.value"
                          :state="getValidationState(validationContext)"
                          :disabled="disabled"
                          maxlength="3"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 2 -->
            <b-row style="margin-bottom: 10px">
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Gewicht (kg)</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q2"
                      rules="required|integer"
                    >
                      <b-form-group
                        label-for="q2"
                      >
                        <b-form-input
                          id="q2"
                          v-model="medicalForm.q2.value"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 3,4,5,6,7 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Moet u regelmatig hoesten?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q3.value"
                      checked="false"
                      name="check-button-q3-value"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(3, 4)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q3.medicalValue"
                      checked="false"
                      name="check-button-q3-medicalValue"
                      switch
                      inline
                      :disabled="!medicalForm.q3.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q3.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Is dat gedurende de laatste 2 jr. regelmatig?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q4.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q4.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q4.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q3.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Hoest u langer dan 3 maanden achter elkaar?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q5.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q5.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q5.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q3.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Hoest u ook slijm op?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q6.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q6.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q6.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q3.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Hoest u ook bloed op?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q7.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q7.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q7.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 8,9,10,11,12,13 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u nog al eens last van kortademigheid?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q8.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(8,5)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q8.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q8.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q8.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">In rust?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q9.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q9.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q9.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q8.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Bij gewoon lopen?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q10.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q10.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q10.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q8.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Bij trap lopen?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q11.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q11.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q11.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q8.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Bij tegenwind lopen of fietsen?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q12.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q12.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q12.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q8.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Komt de kortademigheid in aanvallen van benauwedheid(astma/bezetting)?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q13.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q13.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q13.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 14 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u bronchitis?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q14.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q14.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q14.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 15 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u bezetting?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q15.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q15.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q15.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 16 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u TBC?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q16.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q16.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q16.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 17,18 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u s'nachts vaak last van kortademigheid?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q17.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q17.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q17.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!--            <b-row-->
            <!--              v-if="medicalForm.q17.value"-->
            <!--            >-->
            <!--              <b-col-->
            <!--                cols="12"-->
            <!--                md="6"-->
            <!--              >-->
            <!--                <label class="ml-1 col-form-label">Komt de kortademigheid in aanvallen van benauwedheid(astma/bezetting)?</label>-->
            <!--              </b-col>-->
            <!--              <b-col-->
            <!--                cols="12"-->
            <!--                md="6"-->
            <!--              >-->
            <!--                <b-row>-->
            <!--                  <b-col-->
            <!--                    cols="12"-->
            <!--                    md="5"-->
            <!--                  >-->
            <!--                    <b-form-checkbox-->
            <!--                      v-model="medicalForm.q18.value"-->
            <!--                      checked="false"-->
            <!--                      name="check-button"-->
            <!--                      switch-->
            <!--                      inline-->
            <!--                      :disabled="disabled"-->
            <!--                    >-->
            <!--                      Nee / Ja-->
            <!--                    </b-form-checkbox>-->
            <!--                  </b-col>-->
            <!--                  <b-col-->
            <!--                    cols="12"-->
            <!--                    md="7"-->
            <!--                  >-->
            <!--                    <b-form-checkbox-->
            <!--                      v-model="medicalForm.q18.medicalValue"-->
            <!--                      checked="false"-->
            <!--                      name="check-button"-->
            <!--                      switch-->
            <!--                      inline-->
            <!--                      :disabled="!medicalForm.q18.value || disabled"-->
            <!--                    >-->
            <!--                      Bent u nog onder medische behandeling?-->
            <!--                    </b-form-checkbox>-->
            <!--                  </b-col>-->
            <!--                </b-row>-->
            <!--              </b-col>-->
            <!--            </b-row>-->
            <!-- Question 19 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u geruis aan het hart of trombose?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q19.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q19.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q19.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 20,21,22,23-->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u frequent pijn of een beklemd gevoel op de borst of hartstreek?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q20.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(20,3)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q20.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q20.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q20.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">In rust?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q21.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q21.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q21.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q20.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Bij lichamelijke inspanning?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q22.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q22.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q22.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q20.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Geef een voorbeeld</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q23"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q23"
                      >
                        <b-form-textarea
                          id="q23"
                          v-model="medicalForm.q23.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q23.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="medicalForm.q23.value.length < 1 || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 24,25,26 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u hoge bloeddruk?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q24.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(24,2)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q24.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q24.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q24.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Hoelang heeft u al hoge bloeddruk?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q25"
                      rules="required|between:1,100"
                    >
                      <b-form-group
                        label-for="q25"
                      >
                        <b-form-textarea
                          id="q25"
                          v-model="medicalForm.q25.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q25.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q25.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q24.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Gebruikt u medicatie? (medicatie en dosering vermelden)</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q26"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q26"
                      >
                        <b-form-textarea
                          id="q26"
                          v-model="medicalForm.q26.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q26.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q26.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 27-->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u last van zwelling om uw enkels?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q27.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q27.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q27.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 28,29 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u een verhoogd cholesterolgehalte in het bloed?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q28.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(28,1)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q28.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q28.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q28.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Gebruikt u medicatie? (medicatie en dosering vermelden)</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q29"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q29"
                      >
                        <b-form-textarea
                          id="q29"
                          v-model="medicalForm.q29.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q29.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q29.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 30,31 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u suikerziekte?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q30.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(30,1)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q30.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q30.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q30.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Gebruikt u medicatie? (medicatie en dosering vermelden)</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q31"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q31"
                      >
                        <b-form-textarea
                          id="q31"
                          v-model="medicalForm.q31.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q31.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q31.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 32 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u verschijnselen van steeds hongerig gevoel, dorst hebben en veel urineren?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q32.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q32.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q32.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 33,34,35,36 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u een H.I.V. test gedaan?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q33.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(33,3)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q33.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q33.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q33.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Wanneer?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q34"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q34"
                      >
                        <b-form-textarea
                          id="q34"
                          v-model="medicalForm.q34.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q34.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q34.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q33.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Waarom?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q35"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q35"
                      >
                        <b-form-textarea
                          id="q35"
                          v-model="medicalForm.q35.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q35.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q35.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q33.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Uitslag van de test?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q36.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                    >
                      Negatief / Positief
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q36.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q36.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 37,38,39,40 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u een medische keuring ondergaan?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q37.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(37,3)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q37.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q37.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q37.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Wanneer?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q38"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q38"
                      >
                        <b-form-textarea
                          id="q38"
                          v-model="medicalForm.q38.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q38.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q38.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q37.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Waarom?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q39"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q39"
                      >
                        <b-form-textarea
                          id="q39"
                          v-model="medicalForm.q39.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q39.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="medicalForm.q39.value.length < 1 || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q37.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Resultaat van de keuring?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q40"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q40"
                      >
                        <b-form-textarea
                          id="q40"
                          v-model="medicalForm.q40.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q40.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q40.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 41,42,43,44 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Bent u ooit geopereerd geworden?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q41.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(41,3)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q41.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q41.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q41.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Waarvoor?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q42"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q42"
                      >
                        <b-form-textarea
                          id="q42"
                          v-model="medicalForm.q42.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q42.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q42.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q41.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Wanneer?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q43"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q43"
                      >
                        <b-form-textarea
                          id="q43"
                          v-model="medicalForm.q43.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q43.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q43.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q41.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Door wie werd werd u geopereerd?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q44"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q44"
                      >
                        <b-form-textarea
                          id="q44"
                          v-model="medicalForm.q44.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q44.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q44.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 45,46,47 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Gebruikt u of heeft u enige soort van medicijnen regelmatig gebruikt?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q45.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(45,2)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q45.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q45.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q45.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Welke medicijnen en hoe vaak?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q46"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q46"
                      >
                        <b-form-textarea
                          id="q46"
                          v-model="medicalForm.q46.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q46.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q46.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q45.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Waartegen?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q47"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q47"
                      >
                        <b-form-textarea
                          id="q47"
                          v-model="medicalForm.q47.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q47.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q47.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 48,49,50 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Rookt u?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q48.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions('48',2)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q48.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q48.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q48.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Hoelang rookt u al?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q49"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q49"
                      >
                        <b-form-textarea
                          id="q49"
                          v-model="medicalForm.q49.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q49.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q49.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q48.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Hoeveel sigaretten rookt u per dag?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q50"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q50"
                      >
                        <b-form-textarea
                          id="q50"
                          v-model="medicalForm.q50.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q50.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q50.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 51,52,53 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Bent u gestopt met roken?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q51.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(51,2)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q51.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q51.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q51.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Wanneer bent u gestopt?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q52"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q52"
                      >
                        <b-form-textarea
                          id="q52"
                          v-model="medicalForm.q52.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q52.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q52.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q51.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Hoelang bent u al gestopt met roken?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q53"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q53"
                      >
                        <b-form-textarea
                          id="q53"
                          v-model="medicalForm.q53.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q53.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q53.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 54,55,56,57,58-->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u ooit drugs gebruikt?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q54.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(54,4)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q54.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q54.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q54.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Welke drugs heeft u gebruikt?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q55"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q55"
                      >
                        <b-form-textarea
                          id="q55"
                          v-model="medicalForm.q55.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q55.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q55.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q54.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Wanneer heeft u de drugs gebruikt?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q56"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q56"
                      >
                        <b-form-textarea
                          id="q56"
                          v-model="medicalForm.q56.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q56.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q56.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q54.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Bent u ooit medisch behandeld voor drugsgebruik?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q57.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(57,1)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q57.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q57.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q54.value && medicalForm.q57.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Door wie bent u behandeld?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q58"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q58"
                      >
                        <b-form-textarea
                          id="q58"
                          v-model="medicalForm.q58.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q58.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="medicalForm.q58.value.length < 1 || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 59, 60,61,62,63, 64, 65-->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Gebruikt u alcoholische dranken?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q59.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(59,6)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q59.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q59.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q59.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Wanneer?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                    class=""
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q60"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q60"
                      >
                        <b-form-textarea
                          id="q60"
                          v-model="medicalForm.q60.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q60.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q60.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q59.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Welke?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q61"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q61"
                      >
                        <b-form-textarea
                          id="q61"
                          v-model="medicalForm.q61.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q61.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q61.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q59.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Hoeveel keer per dag?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q62"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q62"
                      >
                        <b-form-textarea
                          id="q62"
                          v-model="medicalForm.q62.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q62.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q62.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q59.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Hoeveel keer per week?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q63"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q63"
                      >
                        <b-form-textarea
                          id="q63"
                          v-model="medicalForm.q63.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q63.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q63.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q59.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Bent u ooit behandeld voor alcoholisme?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q64.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(64,1)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q64.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q64.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q59.value && medicalForm.q64.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Door wie bent u behandeld?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q65"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q65"
                      >
                        <b-form-textarea
                          id="q65"
                          v-model="medicalForm.q65.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q65.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q65.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 66, 67-->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Bent u op dit moment onder medische behandeling voor een of andere ziekte?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q66.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(66,1)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q66.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q66.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q66.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Welke ziekte?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q67"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q67"
                      >
                        <b-form-textarea
                          id="q67"
                          v-model="medicalForm.q67.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q67.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q67.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 68, 69-->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Komt er in uw familie een andere ziekte dan bovengenoemde voor of een andere erfelijke aandoening die bij u bekend is?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q68.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(68,1)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q68.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q68.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q68.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Welke ziekte?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q69"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q69"
                      >
                        <b-form-textarea
                          id="q69"
                          v-model="medicalForm.q69.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q69.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q69.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 70 t/m 112 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Bent u ooit onder behandeling geweest voor 1 of meerdere van de hieronder genoemde ziekten of aandoeningen?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q70.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(67,1)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q70.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Overspannenheid</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q71.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q71.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q71.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Geestelijke aandoening</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q72.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q72.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q72.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Chronische hoofdpijn</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q73.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q73.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q73.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Epilepsie</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q74.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q74.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q74.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Duizeligheid</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q75.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q75.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q75.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Bewustzijnstoornissen</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q76.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q76.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q76.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Reuma</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q77.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q77.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q77.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">SLE</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q78.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q78.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q78.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Jicht</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q79.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q79.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q79.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Artrose</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q80.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                      @change="resetSubQuestions(80,1)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q80.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q80.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q80.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-2 col-form-label">Waar?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q81"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q81"
                      >
                        <b-form-textarea
                          id="q81"
                          v-model="medicalForm.q81.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q81.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q81.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Andere Spier- of gewrichtsklachten</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q82.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                      @change="resetSubQuestions(82,1)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q82.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q82.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q82.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-2 col-form-label">Waar?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q83"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q83"
                      >
                        <b-form-textarea
                          id="q83"
                          v-model="medicalForm.q63.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q83.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q83.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Langdurige nek-/rugklachten</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q84.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q84.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q84.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Galblaas</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q85.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q85.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q85.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Lever (v.b. geelzucht), leveronsteking</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q86.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q86.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q86.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Nieren of blaas</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q87.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q87.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q87.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Staat u s’nachts gewoonlijk meer dan 1 keer op om te urineren?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q88.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q88.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q88.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Huidklachten (v.b. eczeem, psoriasis)</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q89.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q89.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q89.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Kanker of gezwellen</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q90.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q90.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline

                      :disabled="!medicalForm.q90.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Hepatitis B/C</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q91.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q91.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q91.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Aangeboren afwijkingen?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q92.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                      @change="resetSubQuestions(92, 1)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q92.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q92.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q92.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-2 col-form-label">Welke?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q93"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q93"
                      >
                        <b-form-textarea
                          id="q93"
                          v-model="medicalForm.q93.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q93.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="medicalForm.q93.value.length < 1 || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Heeft u last van een lichamelijke en/of geestelijke aandoening, die eerder niet genoemd is?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q94.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                      @change="resetSubQuestions(94, 2)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q94.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q94.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q94.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-2 col-form-label">Welke?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q95"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q95"
                      >
                        <b-form-textarea
                          id="q95"
                          v-model="medicalForm.q95.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q95.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q95.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q94.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-2 col-form-label">Wie is/zijn de behandelende specialist(en) (geweest)?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q96"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q96"
                      >
                        <b-form-textarea
                          id="q96"
                          v-model="medicalForm.q96.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q96.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q96.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Schildklier aandoening en/of hormoonziekte?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q97.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q97.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q97.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Sikkelcellen?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q98.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                      @change="resetSubQuestions(98, 1)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q98.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q98.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q98.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-2 col-form-label">Welke vorm van sikkelcellen heeft u?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q99"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q99"
                      >
                        <div class="demo-inline-spacing">
                          <b-form-radio
                            v-model="medicalForm.q99.value"
                            class="mt-0"
                            plain
                            name="q99"
                            value="AS"
                            :state="getValidationState(validationContext)"
                            :disabled="!medicalForm.q98.value || disabled"
                          >
                            AS
                          </b-form-radio>
                          <b-form-radio
                            v-model="medicalForm.q99.value"
                            class="mt-0"
                            plain
                            name="q99"
                            value="SS"
                            :state="getValidationState(validationContext)"
                            :disabled="!medicalForm.q98.value || disabled"
                          >
                            SS
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q99.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q99.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Verlammingen en zenuwstoornissen</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q100.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q100.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q100.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!--            <b-row>-->
            <!--              <b-col-->
            <!--                cols="12"-->
            <!--                md="6"-->
            <!--              >-->
            <!--                <label class="ml-1 col-form-label">Verlammingen en zenuwstoornissen</label>-->
            <!--              </b-col>-->
            <!--              <b-col-->
            <!--                cols="12"-->
            <!--                md="6"-->
            <!--              >-->
            <!--                <b-row>-->
            <!--                  <b-col-->
            <!--                    cols="12"-->
            <!--                    md="5"-->
            <!--                  >-->
            <!--                    <b-form-checkbox-->
            <!--                      v-model="medicalForm.q100.value"-->
            <!--                      checked="false"-->
            <!--                      name="check-button"-->
            <!--                      switch-->
            <!--                      inline-->
            <!--                      :disabled="!medicalForm.q70.value || disabled"-->
            <!--                    >-->
            <!--                      Nee / Ja-->
            <!--                    </b-form-checkbox>-->
            <!--                  </b-col>-->
            <!--                  <b-col-->
            <!--                    cols="12"-->
            <!--                    md="7"-->
            <!--                  >-->
            <!--                    <b-form-checkbox-->
            <!--                      v-model="medicalForm.q100.medicalValue"-->
            <!--                      checked="false"-->
            <!--                      name="check-button"-->
            <!--                      switch-->
            <!--                      inline-->
            <!--                      :disabled="!medicalForm.q100.value || disabled"-->
            <!--                    >-->
            <!--                      Bent u nog onder medische behandeling?-->
            <!--                    </b-form-checkbox>-->
            <!--                  </b-col>-->
            <!--                </b-row>-->
            <!--              </b-col>-->
            <!--            </b-row>-->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Maagklachten, darmklachten, een lever - of een galblaasaandoening</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q101.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q101.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q101.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Een geslachtsziekte of sexueel overdraagbare aandoening, een aandoening van het geslachtsorgaan</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q102.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q102.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q102.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Een zenuwontsteking, reuma, botziekte, botbreuken, afwijkingen aan de wervelkolom of frequente rugpijn</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q103.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q103.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q103.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Lage Sali (bloedarmoede) of een andere bloedstoornis</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q104.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q104.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q104.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Bewusteloosheid</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q105.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q105.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q105.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Aanhoudende heesheid</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q106.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q106.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q106.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Knobbels in uw hals, oksels of liezen</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q107.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q107.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q107.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Langer dan 2 maanden aanhoudende klachten van erge vermoeidheid, persisterende koorts met nachtzweten of persisterende diarree</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q108.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q108.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q108.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Ernstig gewichtsverlies zonder dieetmaatregelen</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q109.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q109.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q109.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Langer dan 3 maanden aanhoudende hoest, die niet in relatie is met roken?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q110.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q110.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q110.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Verhoogde bloedingsneiging, dengue met of zonder complicaties of malaria</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q111.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q70.value || disabled"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q111.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="!medicalForm.q111.value || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 113, 114, 115, 116 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Bent u ooit opgenomen in een ziekeninrichting?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q112.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(113,3)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q112.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q112.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q112.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Waarvoor?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q113"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q113"
                      >
                        <b-form-textarea
                          id="q113"
                          v-model="medicalForm.q113.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q113.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="medicalForm.q113.value.length < 1 || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q112.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Wanneer?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q114"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q114"
                      >
                        <b-form-textarea
                          id="q114"
                          v-model="medicalForm.q114.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q114.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q114.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q112.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Wie is/zijn de behandelende specialist(en) (geweest)?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q115"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q115"
                      >
                        <b-form-textarea
                          id="q115"
                          v-model="medicalForm.q115.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q115.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q115.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 117, 118, 119 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Doet u regelmatig aan sport?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q116.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(116,2)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q116.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q116.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q116.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Welke?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q117"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q117"
                      >
                        <b-form-textarea
                          id="q117"
                          v-model="medicalForm.q117.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q117.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q117.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q116.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Hoevaak (p/mnd)?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q118"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q118"
                      >
                        <b-form-textarea
                          id="q118"
                          v-model="medicalForm.q118.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q118.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q118.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 120, 121 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u ooit eens een bloedtransfusie gehad?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q119.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(119,1)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q119.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q119.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q119.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Waar heeft dit plaatsgevonden (plaats, land en ziekenhuis)?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q120"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q120"
                      >
                        <b-form-textarea
                          id="q120"
                          v-model="medicalForm.q120.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q120.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="medicalForm.q120.value.length < 1 || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 122, 123, 124 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Is uw bloed weleens onderzocht?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q121.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(121,1)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q121.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q121.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q121.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Wanneer?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q122"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q122"
                      >
                        <b-form-textarea
                          id="q122"
                          v-model="medicalForm.q122.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q122.medicalValue"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="medicalForm.q122.value.length < 1 || disabled"
                    >
                      Bent u nog onder medische behandeling?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!--            <b-row-->
            <!--              v-if="medicalForm.q121.value"-->
            <!--            >-->
            <!--              <b-col-->
            <!--                cols="12"-->
            <!--                md="6"-->
            <!--              >-->
            <!--                <label class="ml-1 col-form-label">Hoevaak (p/mnd)?</label>-->
            <!--              </b-col>-->
            <!--              <b-col-->
            <!--                cols="12"-->
            <!--                md="6"-->
            <!--              >-->
            <!--                <b-row>-->
            <!--                  <b-col-->
            <!--                    cols="12"-->
            <!--                    md="5"-->
            <!--                  >-->
            <!--                    <validation-provider-->
            <!--                        #default="validationContext"-->
            <!--                        name="q123"-->
            <!--                        rules="required"-->
            <!--                    >-->
            <!--                      <b-form-group-->
            <!--                          label-for="q123"-->
            <!--                      >-->
            <!--                        <b-form-textarea-->
            <!--                            id="q123"-->
            <!--                            v-model="medicalForm.q123.value"-->
            <!--                            rows="2"-->
            <!--                            :disabled="disabled"-->
            <!--                            :state="getValidationState(validationContext)"-->
            <!--                        ></b-form-textarea>-->
            <!--                        <b-form-invalid-feedback :state="getValidationState(validationContext)">-->
            <!--                          {{ validationContext.errors[0] }}-->
            <!--                        </b-form-invalid-feedback>-->
            <!--                      </b-form-group>-->
            <!--                    </validation-provider>-->
            <!--                  </b-col>-->
            <!--&lt;!&ndash;                  <b-col&ndash;&gt;-->
            <!--&lt;!&ndash;                    cols="12"&ndash;&gt;-->
            <!--&lt;!&ndash;                    md="7"&ndash;&gt;-->
            <!--&lt;!&ndash;                  >&ndash;&gt;-->
            <!--&lt;!&ndash;                    <b-form-checkbox&ndash;&gt;-->
            <!--&lt;!&ndash;                      v-model="medicalForm.q123.medicalValue"&ndash;&gt;-->
            <!--&lt;!&ndash;                      checked="false"&ndash;&gt;-->
            <!--&lt;!&ndash;                      name="check-button"&ndash;&gt;-->
            <!--&lt;!&ndash;                      switch&ndash;&gt;-->
            <!--&lt;!&ndash;                      inline&ndash;&gt;-->
            <!--&lt;!&ndash;                      :disabled="medicalForm.q123.value.length < 1 || disabled"&ndash;&gt;-->
            <!--&lt;!&ndash;                    >&ndash;&gt;-->
            <!--&lt;!&ndash;                      Bent u nog onder medische behandeling?&ndash;&gt;-->
            <!--&lt;!&ndash;                    </b-form-checkbox>&ndash;&gt;-->
            <!--&lt;!&ndash;                  </b-col>&ndash;&gt;-->
            <!--                </b-row>-->
            <!--              </b-col>-->
            <!--            </b-row>-->
            <!-- Question 125, 126 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft u een ziektekostenverzekering?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q124.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(124,1)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q124.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q124.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q124.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Waar?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q125"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q125"
                      >
                        <b-form-textarea
                          id="q126"
                          v-model="medicalForm.q125.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q125.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q125.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 127, 128 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Geniet u krachtens een CAO of arbeidsovereenkomst, medische voorzieningen?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q126.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(126,1)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q126.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q126.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q126.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Welke?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q127"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q127"
                      >
                        <b-form-textarea
                          id="q127"
                          v-model="medicalForm.q127.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q127.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q127.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- Question 129, 130, 131 -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <label class="col-form-label"><strong>Heeft een verzekeringsmaatschappij u ooit een levens- en/of ziektenkostenverzekering geweigerd, opgezegd, of deze op bijzondere voorwaarden geaccepteerd?</strong></label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-checkbox
                      v-model="medicalForm.q128.value"
                      checked="false"
                      name="check-button"
                      switch
                      inline
                      :disabled="disabled"
                      @change="resetSubQuestions(128,2)"
                    >
                      Nee / Ja
                    </b-form-checkbox>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q128.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!medicalForm.q128.value || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q128.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Waarom?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q129"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q129"
                      >
                        <b-form-textarea
                          id="q129"
                          v-model="medicalForm.q129.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q129.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q129.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="medicalForm.q128.value"
            >
              <b-col
                cols="12"
                md="6"
              >
                <label class="ml-1 col-form-label">Welke maatschappij?</label>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="q130"
                      rules="required"
                    >
                      <b-form-group
                        label-for="q130"
                      >
                        <b-form-textarea
                          id="q130"
                          v-model="medicalForm.q130.value"
                          rows="2"
                          :disabled="disabled"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!--                  <b-col-->
                  <!--                    cols="12"-->
                  <!--                    md="7"-->
                  <!--                  >-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="medicalForm.q130.medicalValue"-->
                  <!--                      checked="false"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="medicalForm.q130.value.length < 1 || disabled"-->
                  <!--                    >-->
                  <!--                      Bent u nog onder medische behandeling?-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <!-- 132 -->
            <b-row class="mt-1 medicalFormLabel">
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Wie was uw vorige huisdokter"
                  label-for="q131"
                >
                  <b-form-input
                    id="q131"
                    v-model="medicalForm.q131.value"
                    type="text"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Wie is uw huidige dokter?"
                  label-for="q132"
                >
                  <b-form-input
                    id="q132"
                    v-model="medicalForm.q132.value"
                    type="text"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Adres huidige dokter?"
                  label-for="q133"
                >
                  <b-form-input
                    id="q133"
                    v-model="medicalForm.q133.value"
                    type="text"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Telefoonnummer huidige dokter?"
                  label-for="q134"
                >
                  <b-form-input
                    id="q134"
                    v-model="medicalForm.q134.value"
                    type="text"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Fax huidige dokter?"
                  label-for="q135"
                >
                  <b-form-input
                    id="q135"
                    v-model="medicalForm.q135.value"
                    type="text"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Wie is uw werkgever?"
                  label-for="q136"
                >
                  <b-form-input
                    id="q136"
                    v-model="medicalForm.q136.value"
                    type="text"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Beroep/Functie?"
                  label-for="q137"
                >
                  <b-form-input
                    id="q137"
                    v-model="medicalForm.q137.value"
                    type="text"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Datum indiensttreding huidige werkgever?"
                  label-for="q138"
                >
                  <flat-pickr
                    id="q138"
                    v-model="medicalForm.q138.value"
                    :config="employmentDateConfig"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-if="!disabled"
              class="mt-2 mb-0"
            >
              <b-col cols="12 text-right mb-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                >
                  <span>Opslaan</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card-actions>
      </b-card>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm,
  BCard,
  BCardHeader,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BButton,
  BFormInvalidFeedback,
  BFormTextarea, BFormRadio, BLink,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    BCardActions,
    BFormGroup,
    BButton,
    BFormInvalidFeedback,
    BFormTextarea,
    flatPickr,
    BFormRadio,
    BLink,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    formId: {
      type: String,
      default: () => '',
    },
    relation: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      output: null,
      employmentDateConfig: {
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      visible: false,
      medicalForm: {
        q1: {
          value: '',
        },
        q2: {
          value: '',
        },
        q3: {
          value: false,
          medicalValue: false,
        },
        q4: {
          value: false,
          medicalValue: false,
        },
        q5: {
          value: false,
          medicalValue: false,
        },
        q6: {
          value: false,
          medicalValue: false,
        },
        q7: {
          value: false,
          medicalValue: false,
        },
        q8: {
          value: false,
          medicalValue: false,
        },
        q9: {
          value: false,
          medicalValue: false,
        },
        q10: {
          value: false,
          medicalValue: false,
        },
        q11: {
          value: false,
          medicalValue: false,
        },
        q12: {
          value: false,
          medicalValue: false,
        },
        q13: {
          value: false,
          medicalValue: false,
        },
        q14: {
          value: false,
          medicalValue: false,
        },
        q15: {
          value: false,
          medicalValue: false,
        },
        q16: {
          value: false,
          medicalValue: false,
        },
        q17: {
          value: false,
          medicalValue: false,
        },
        q18: {
          value: false,
          medicalValue: false,
        },
        q19: {
          value: false,
          medicalValue: false,
        },
        q20: {
          value: false,
          medicalValue: false,
        },
        q21: {
          value: false,
          medicalValue: false,
        },
        q22: {
          value: false,
          medicalValue: false,
        },
        q23: {
          value: '',
          medicalValue: false,
        },
        q24: {
          value: false,
          medicalValue: false,
        },
        q25: {
          value: '',
          medicalValue: false,
        },
        q26: {
          value: '',
          medicalValue: false,
        },
        q27: {
          value: false,
          medicalValue: false,
        },
        q28: {
          value: false,
          medicalValue: false,
        },
        q29: {
          value: '',
          medicalValue: false,
        },
        q30: {
          value: false,
          medicalValue: false,
        },
        q31: {
          value: '',
          medicalValue: false,
        },
        q32: {
          value: false,
          medicalValue: false,
        },
        q33: {
          value: false,
          medicalValue: false,
        },
        q34: {
          value: '',
          medicalValue: false,
        },
        q35: {
          value: '',
          medicalValue: false,
        },
        q36: {
          value: false,
          medicalValue: false,
        },
        q37: {
          value: false,
          medicalValue: false,
        },
        q38: {
          value: '',
          medicalValue: false,
        },
        q39: {
          value: '',
          medicalValue: false,
        },
        q40: {
          value: '',
          medicalValue: false,
        },
        q41: {
          value: false,
          medicalValue: false,
        },
        q42: {
          value: '',
          medicalValue: false,
        },
        q43: {
          value: '',
          medicalValue: false,
        },
        q44: {
          value: '',
          medicalValue: false,
        },
        q45: {
          value: false,
          medicalValue: false,
        },
        q46: {
          value: '',
          medicalValue: false,
        },
        q47: {
          value: '',
          medicalValue: false,
        },
        q48: {
          value: false,
          medicalValue: false,
        },
        q49: {
          value: '',
          medicalValue: false,
        },
        q50: {
          value: '',
          medicalValue: false,
        },
        q51: {
          value: false,
          medicalValue: false,
        },
        q52: {
          value: '',
          medicalValue: false,
        },
        q53: {
          value: '',
          medicalValue: false,
        },
        q54: {
          value: false,
          medicalValue: false,
        },
        q55: {
          value: '',
          medicalValue: false,
        },
        q56: {
          value: '',
          medicalValue: false,
        },
        q57: {
          value: false,
          medicalValue: false,
        },
        q58: {
          value: '',
          medicalValue: false,
        },
        q59: {
          value: false,
          medicalValue: false,
        },
        q60: {
          value: '',
          medicalValue: false,
        },
        q61: {
          value: '',
          medicalValue: false,
        },
        q62: {
          value: '',
          medicalValue: false,
        },
        q63: {
          value: '',
          medicalValue: false,
        },
        q64: {
          value: false,
          medicalValue: false,
        },
        q65: {
          value: '',
          medicalValue: false,
        },
        q66: {
          value: false,
          medicalValue: false,
        },
        q67: {
          value: '',
          medicalValue: false,
        },
        q68: {
          value: false,
          medicalValue: false,
        },
        q69: {
          value: '',
          medicalValue: false,
        },
        q70: {
          value: false,
          medicalValue: false,
        },
        q71: {
          value: false,
          medicalValue: false,
        },
        q72: {
          value: false,
          medicalValue: false,
        },
        q73: {
          value: false,
          medicalValue: false,
        },
        q74: {
          value: false,
          medicalValue: false,
        },
        q75: {
          value: false,
          medicalValue: false,
        },
        q76: {
          value: false,
          medicalValue: false,
        },
        q77: {
          value: false,
          medicalValue: false,
        },
        q78: {
          value: false,
          medicalValue: false,
        },
        q79: {
          value: false,
          medicalValue: false,
        },
        q80: {
          value: false,
          medicalValue: false,
        },
        q81: {
          value: '',
          medicalValue: false,
        },
        q82: {
          value: false,
          medicalValue: false,
        },
        q83: {
          value: '',
          medicalValue: false,
        },
        q84: {
          value: false,
          medicalValue: false,
        },
        q85: {
          value: false,
          medicalValue: false,
        },
        q86: {
          value: false,
          medicalValue: false,
        },
        q87: {
          value: false,
          medicalValue: false,
        },
        q88: {
          value: false,
          medicalValue: false,
        },
        q89: {
          value: false,
          medicalValue: false,
        },
        q90: {
          value: false,
          medicalValue: false,
        },
        q91: {
          value: false,
          medicalValue: false,
        },
        q92: {
          value: false,
          medicalValue: false,
        },
        q93: {
          value: '',
          medicalValue: false,
        },
        q94: {
          value: false,
          medicalValue: false,
        },
        q95: {
          value: '',
          medicalValue: false,
        },
        q96: {
          value: '',
          medicalValue: false,
        },
        q97: {
          value: '',
          medicalValue: false,
        },
        q98: {
          value: false,
          medicalValue: false,
        },
        q99: {
          value: '',
          medicalValue: false,
        },
        q100: {
          value: false,
          medicalValue: false,
        },
        q101: {
          value: false,
          medicalValue: false,
        },
        q102: {
          value: false,
          medicalValue: false,
        },
        q103: {
          value: false,
          medicalValue: false,
        },
        q104: {
          value: false,
          medicalValue: false,
        },
        q105: {
          value: false,
          medicalValue: false,
        },
        q106: {
          value: false,
          medicalValue: false,
        },
        q107: {
          value: false,
          medicalValue: false,
        },
        q108: {
          value: false,
          medicalValue: false,
        },
        q109: {
          value: false,
          medicalValue: false,
        },
        q110: {
          value: false,
          medicalValue: false,
        },
        q111: {
          value: false,
          medicalValue: false,
        },
        q112: {
          value: false,
          medicalValue: false,
        },
        q113: {
          value: '',
          medicalValue: false,
        },
        q114: {
          value: '',
          medicalValue: false,
        },
        q115: {
          value: '',
          medicalValue: false,
        },
        q116: {
          value: false,
          medicalValue: false,
        },
        q117: {
          value: '',
          medicalValue: false,
        },
        q118: {
          value: '',
          medicalValue: false,
        },
        q119: {
          value: false,
          medicalValue: false,
        },
        q120: {
          value: '',
          medicalValue: false,
        },
        q121: {
          value: false,
          medicalValue: false,
        },
        q122: {
          value: '',
          medicalValue: false,
        },
        q123: {
          value: '',
          medicalValue: false,
        },
        q124: {
          value: false,
          medicalValue: false,
        },
        q125: {
          value: '',
          medicalValue: false,
        },
        q126: {
          value: false,
          medicalValue: false,
        },
        q127: {
          value: '',
          medicalValue: false,
        },
        q128: {
          value: false,
          medicalValue: false,
        },
        q129: {
          value: '',
          medicalValue: false,
        },
        q130: {
          value: '',
          medicalValue: false,
        },
        q131: {
          value: '',
        },
        q132: {
          value: '',
        },
        q133: {
          value: '',
        },
        q134: {
          value: '',
        },
        q135: {
          value: '',
        },
        q136: {
          value: '',
        },
        q137: {
          value: '',
        },
        q138: {
          value: '',
        },
      },
      blankMedicalForm: {},
      firstLoadView: true,
      formStatusIcon: 'icon-alert-circle icon-color-warning',
      collapsed: true,
      isValid: false,
    }
  },
  watch: {
    medicalForm: {
      handler() {
        if (!this.disabled) {
          this.isFormValid()
        }
      },
      deep: true,
    },
    disabled: {
      handler(value) {
        if (value) {
          this.formStatusIcon = 'icon-check-circle icon-color-succes'
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.blankMedicalForm = JSON.parse(JSON.stringify(this.medicalForm))
    if (this.disabled) {
      this.medicalForm = this.formData
      this.$refs.refFormObserver.reset()
    }
  },
  methods: {
    print() {
      const canvasElement = document.createElement('canvas')
      this.$html2canvas(this.$refs.printMe, { canvas: canvasElement }).then(async canvas => {
        const img = canvas.toDataURL('image/jpeg', 1.0)
        let html = '<html><head><title></title></head>'
        html += '<body style="max-width:923px; padding: 0; margin: 0;">'
        html += `<div style="max-width:923px"><img style="max-width:923px" src="${img}" /></div></body></html>`

        const newWindow = window.open()
        await newWindow.document.write(html)
        await newWindow.document.close()
        await newWindow.focus()
        await newWindow.print()
        return false
      })
    },
    async isFormValid() {
      await this.$refs.refFormObserver.validate().then(result => {
        if (result) {
          this.formStatusIcon = 'icon-check-circle icon-color-succes'
          this.isValid = true
        }

        if (!result) {
          this.formStatusIcon = 'icon-alert-circle icon-color-warning'
          this.isValid = false
        }
      })
    },
    resetSubQuestions(questionId, count) {
      if (!this.firstLoadView) {
        if (questionId !== undefined) {
          if (this.medicalForm[`q${String(questionId)}`].value === false) {
            this.medicalForm[`q${String(questionId)}`].medicalValue = false
            for (let i = 1; i <= count; i += 1) {
              if (this.medicalForm[`q${String(i + questionId)}`]) {
                this.medicalForm[`q${String(i + questionId)}`].value = this.blankMedicalForm[`q${String(i + questionId)}`].value
                this.medicalForm[`q${String(i + questionId)}`].medicalValue = this.blankMedicalForm[`q${String(i + questionId)}`].medicalValue
              }
            }
          }
        }
      } else {
        this.firstLoadView = false
      }
    },
    getFullName(data) {
      let fullName = ''

      if (data) {
        if (data.middleName && data.middleName.length > 0) {
          fullName = `${data.firstName} ${data.middleName} ${data.lastName}`
        } else {
          fullName = `${data.firstName} ${data.lastName}`
        }
      }

      return fullName
    },
    onSubmit() {
      this.$refs.cardActions.triggerCollapsed()
      this.$emit('save-form-data', { relationID: this.relation.id, form: this.medicalForm })
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    return {
      refFormObserver,
      getValidationState,
      resetForm,

      // FOrm Validators
      required,
      integer,
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';

.icon-color-warning{
  color: #ea5455;
}

.icon-color-succes{
  color: #28c76f;
}

.medicalFormLabel label {
  font-size: 1rem !important;
  font-weight: 600 !important;
}
</style>

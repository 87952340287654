import { ref } from '@vue/composition-api'

export default function useRelationDocumentsList() {
  const refRelationDocumentsTable = ref(null)

  // Table Handlers
  const tableRelationDocumentsColumns = [
    { label: 'Naam', key: 'description', sortable: true },
    { label: 'Type', key: 'type', sortable: true },
    { label: 'Status', key: 'validationStatus', sortable: true },
    { label: 'Uploaddatum', key: 'file.timestamp', sortable: true },
    { label: 'Acties', key: 'actions' },
  ]

  const isSortDirDesc = ref(true)

  const refetchRelationDocumentsData = () => {
    refRelationDocumentsTable.value.refresh()
  }

  const documentOptionsConsumer = [
    { label: 'Identiteitsbewijs', value: 1 },
    { label: 'CBB', value: 2 },
    { label: 'Overig', value: 99 },
  ]

  const documentOptionsBusiness = [
    { label: 'KFF', value: 3 },
    { label: 'Statuten', value: 4 },
    { label: 'Overig', value: 99 },
  ]

  const resolveUserStatusVariant = status => {
    if (status === '1') return 'Identiteitsbewijs'
    if (status === '2') return 'CBB'
    if (status === '3') return 'KFF'
    if (status === '4') return 'Statuten'
    return 'Overig'
  }

  return {
    tableRelationDocumentsColumns,
    refRelationDocumentsTable,
    refetchRelationDocumentsData,
    isSortDirDesc,
    documentOptionsConsumer,
    documentOptionsBusiness,
    resolveUserStatusVariant,
  }
}

<template>
  <div>
    <b-modal
      id="modal-pdf-viewer"
      ref="modalPdfViewer"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-title="Close"
      centered
      title=""
      :hide-footer="true"
      size="lg"
    >
      <pdf-viewer />
    </b-modal>
    <b-modal
      id="modal-validatie-document"
      ref="modalValidatieDocument"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-disabled
      cancel-title="Close"
      centered
      title="Document validatie"
      :hide-footer="true"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormDocumentValidatieObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(validateDocument)"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="validTill"
                rules="required|date"
              >
                <b-form-group
                  label="Geldig tot"
                  label-for="validTill"
                  :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                    id="validTill"
                    v-model="validateDocumentObject.validTill"
                    :config="validateDocumentDateConfig"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <b-spinner
                  v-if="showAcceptDocumentSpinner"
                  small
                  class="mr-1"
                />
                Accepteren
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-relation-documents-upload"
      ref="modalRelationDocumentsUpload"
      hide-footer
      centered
      size="md"
      title="Bestanden Uploaden"
    >
      <b-card>
        <b-row>
          <b-col
            cols="12"
          >
            <validation-observer
              ref="refRelationDocumentAddFormObserver"
            >
              <b-form>
                <validation-provider
                  #default="validationContext"
                  name="fileType"
                  rules="required"
                >
                  <b-form-group
                    label="Type"
                    label-for="fileType"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="fileType"
                      v-model="fileType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="documentOptions"
                      :reduce="val => val.value"
                      :clearable="true"
                      input-id="active"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form>
            </validation-observer>
          </b-col>
          <b-col
            cols="12"
          >
            <b-card-text>
              <b-button
                variant="primary"
                :disabled="uploadLoader || !valid"
                @click="$refs.refInputEl.click()"
              >
                <b-spinner
                  v-if="uploadLoader"
                  small
                  class="mr-1"
                />
                <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @input="uploadDocument"
                >
                <span class="d-none d-sm-inline">Uploaden</span>
                <feather-icon
                  icon="EditIcon"
                  class="d-inline d-sm-none"
                />
              </b-button>
            </b-card-text>
          </b-col>
          <b-col
            class="mt-1"
            cols="12"
          >
            <b-card-text
              cols="12"
              class="text-muted"
            >
              Toegestaan PDF, GIF of PNG. Maximale grootte van 2MB.
            </b-card-text>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
    <b-col
      cols="12"
    >
      <b-card
        title="Documenten"
      >
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-rigth justify-content-end mb-1 mb-md-0"
            >
              <b-button
                v-if="$can('update', 'order-file-upload')"
                variant="primary"
                @click="openUploadRelationDocumentsModal"
              >
                Toevoegen
              </b-button>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refRelationDocumentsTable"
          :items="files"
          responsive
          :fields="tableRelationDocumentsColumns"
          primary-key="id"
          show-empty
          empty-text="Geen overeenkomende records gevonden"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >
          <template #cell(validationStatus)="data">
            <b-badge :variant="documentStatusVariantParser(data.value)">
              {{ documentStatusParser(data.value) }}
            </b-badge>
          </template>

          <template #cell(file.timestamp)="data">
            <span class="text-nowrap">
              {{ formatDateTime(data.value) }}
            </span>
          </template>

          <template #cell(type)="data">
            {{ resolveUserStatusVariant(data.value) }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <feather-icon
                v-if="data.item.file.extension === 'pdf'"
                :id="`pdf-row-${data.item.id}-preview-icon`"
                class="cursor-pointer mx-1"
                icon="EyeIcon"
                size="16"
                style="color:#fdb813"
                @click="viewDocument(data.item.fileURL)"
              />
              <b-link
                v-if="$can('read', 'order-file-download')"
                :href="data.item.fileURL"
              >
                <feather-icon
                  :id="`document-row-${data.item.fileID}-preview-icon`"
                  class="cursor-pointer mx-1"
                  icon="DownloadIcon"
                  size="16"
                />
              </b-link>
              <feather-icon
                v-if="$can('read', 'order-file-download') && data.item.validationStatus === 0"
                :id="`document-row-${data.item.id}-approval-icon`"
                class="cursor-pointer mx-1"
                icon="CheckCircleIcon"
                size="16"
                style="color:rgb(40,199,111);"
                @click="modalValidatieDocument(data.item.id)"
              />
              <feather-icon
                v-if="$can('read', 'order-file-download') && data.item.validationStatus === 0"
                :id="`document-row-${data.item.id}-disapprive-icon`"
                class="cursor-pointer mx-1"
                icon="XCircleIcon"
                size="16"
                style="color:rgb(234,84,85) !important;"
                @click="confirmDisapproveDocumentText(data.item.id)"
              />
            </div>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </div>
</template>

<script>
import { required } from '@validations'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import relationDocumentsStoreModule from '@core/components/app-relation-documents/relationDocumentsStoreModule'
import formValidation from '@core/comp-functions/forms/form-validation'
import { formatDateTime } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'
import useRelationDocumentsList from '@core/components/app-relation-documents/useRelationDocumentsList'
import {
  BBadge,
  BButton, BCard, BCardText, BCol, BForm, BFormGroup, BFormInvalidFeedback, BLink, BRow, BSpinner, BTable,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import PdfViewer from '@/views/pages/pdf-viewer/PdfViewer.vue'

export default {
  components: {
    BTable,
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BCol,
    BRow,
    BCardText,
    BSpinner,
    BLink,
    flatPickr,
    BBadge,
    PdfViewer,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    relationId: {
      type: Number,
      default: () => null,
    },
    files: {
      type: Array,
      default: () => [],
    },
    relationType: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      required,
      uploadLoader: false,
      documentOptions: [],
      fileType: null,
      valid: false,
      showAcceptDocumentSpinner: false,
      validateDocumentObject: {
        validTill: '',
        validationStatus: 1,
        id: null,
        type: 'RelationFile',
      },
      validateDocumentDateConfig: {
        minDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
    }
  },
  watch: {
    fileType: {
      immediate: true,
      async handler() {
        if (this.$refs.refRelationDocumentAddFormObserver) {
          await this.$refs.refRelationDocumentAddFormObserver.validate().then(valid => {
            this.valid = valid
          })
        }
      },
    },
    relationType: {
      immediate: true,
      handler(value) {
        if (value === 1) {
          this.documentOptions = this.documentOptionsConsumer
        }
        if (value === 2) {
          this.documentOptions = this.documentOptionsBusiness
        }
      },
    },
  },
  methods: {
    viewDocument(url) {
      this.$store.commit('app/UPDATE_PDF_URL', url)
      this.$refs.modalPdfViewer.show()
    },
    openUploadRelationDocumentsModal() {
      this.$refs.modalRelationDocumentsUpload.show()
    },
    modalValidatieDocument(id) {
      this.validateDocumentObject.id = id
      this.$refs.modalValidatieDocument.show()
    },
    uploadDocument() {
      this.uploadLoader = true
      const file = document.querySelector('input[type=file]').files[0]
      if (file.size > 10485760) {
        this.uploadLoader = false
        this.$swal({
          icon: 'error',
          title: 'Afbeeldingsgrootte',
          text: 'De maximale afbeeldinggrootte is 10mb. Het huidige bestand is te groot.',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      } else {
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            const fileUploadData = {
              file: reader.result,
              type: 'RelationFile',
              fileType: this.fileType,
              name: file.name,
              relationID: this.relationId,
            }

            store.dispatch('app-relation-documents/uploadRelationDocument', fileUploadData)
              .then(() => {
                this.uploadLoader = false
                this.$refs.modalRelationDocumentsUpload.hide()
                this.$emit('refetch-data')
              })
              .catch(() => {
                this.uploadLoader = false
                this.$swal({
                  icon: 'error',
                  title: 'Uploaden',
                  text: `${errorAndSuccesDefaultMessage.uploadDataError}`,
                  customClass: {
                    confirmButton: 'btn btnOX-danger',
                  },
                })
              })
          }, false,
        )

        reader.readAsDataURL(file)
      }
    },
    validateDocument() {
      store.dispatch('app-relation-documents/validateDocument', this.validateDocumentObject)
        .then(() => {
          this.$refs.modalValidatieDocument.hide()
          this.$emit('refetch-data')
        })
        .catch(() => {
          this.$refs.modalValidatieDocument.hide()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Document validatie',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${errorAndSuccesDefaultMessage.saveDataError}`,
            },
          })
        })
    },
    confirmDisapproveDocumentText(orderID) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, document afwijzen!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-relation-documents/validateDocument', { id: orderID, validationStatus: -1, type: 'RelationFile' })
            .then(() => {
              this.$emit('refetch-data')
              this.$swal({
                icon: 'success',
                title: 'Document!',
                text: 'Het document is afgewezen.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Document afwijzen',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: `${errorAndSuccesDefaultMessage.saveDataError}`,
                },
              })
            })
        }
      })
    },
    documentStatusVariantParser(status) {
      switch (status) {
        case 1:
          return 'success'
        case -1:
          return 'danger'
        case -2:
          return 'danger'
        default:
          return 'warning'
      }
    },
    documentStatusParser(status) {
      switch (status) {
        case 1:
          return 'Gevalideerd'
        case -1:
          return 'Afgewezen'
        case -2:
          return 'Verlopen'
        default:
          return 'Wacht op validatie'
      }
    },
  },
  setup() {
    const ORDER_DETAIL_MODULE_NAME = 'app-relation-documents'

    // Register module
    if (!store.hasModule(ORDER_DETAIL_MODULE_NAME)) store.registerModule(ORDER_DETAIL_MODULE_NAME, relationDocumentsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORDER_DETAIL_MODULE_NAME)) store.unregisterModule(ORDER_DETAIL_MODULE_NAME)
    })

    const {
      tableRelationDocumentsColumns,
      refRelationDocumentsTable,
      refetchRelationDocumentsData,
      isSortDirDesc,
      documentOptionsConsumer,
      documentOptionsBusiness,
      resolveUserStatusVariant,
    } = useRelationDocumentsList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      tableRelationDocumentsColumns,
      refRelationDocumentsTable,
      refetchRelationDocumentsData,
      refFormObserver,
      getValidationState,
      resetForm,
      isSortDirDesc,
      documentOptionsConsumer,
      documentOptionsBusiness,
      resolveUserStatusVariant,
      formatDateTime,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-locations-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
